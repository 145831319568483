import axios from '@shared/services/api/axiosConfig.js';
import { getAuthHeaders } from '@shared/services/auth/authApiServices.js';

const debug = process.env.NODE_ENV !== 'production';

export default {
  async getPreFill(proposalId){
    const headers = await getAuthHeaders();
    const res = await axios.get(`/api/proposal/${proposalId}/prefill`, headers)
    if (debug) console.log('getPreFill', res);
    return res;
  },
};
