<template>
  <div class="ce-paragraph">
    <div class="ce-in" placeholder="Enter Text Here..." contenteditable="true" ref="editable" @keydown="onKeyDown"  @blur="update">
    </div>
  </div>
</template>

<script>
import scrollMeTo from '@shared/components/stepper/utils/scrollMeTo.js';
// Inspiration from Editorjs https://github.com/editor-js/paragraph/blob/master/src/index.js
// and https://github.com/editor-js/list/blob/master/src/index.js

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    active: Boolean
  },
  mounted() {
    this.initText(this.value);
    this.initCaret(this.active);
  },
  watch: {
    value: function(value) {
      this.initText(value);
    },
  },
  methods: {
    initText(value) {
      this.$refs.editable.innerText = value;
    },
    initCaret(value) {
      if (value === true) {
        scrollMeTo.setCaret(this.$refs.editable, 0);
      }
    },
    update(e) {
      let clean = e.target.innerText.trim();
      e.target.innerText = clean;
      this.$emit('input', e.target.innerText);
    },
    onKeyDown(event) {
      const [ENTER, BACKSPACE] = [13, 8]; // key codes
      switch (event.keyCode) {
        case ENTER:
          this.onEnter(event)
          break;
        case BACKSPACE:
          this.backspace(event);
          break;
      }
    },
    onEnter(e) {
      let clean = e.target.innerText.replace('<br/>', '').replace('\n', '').trim();
      e.target.innerText = clean;

      this.$emit('input', clean);
      this.$emit('newEntry');

      e.preventDefault();
      e.stopPropagation();
    },
    backspace(event){
      let currentText = event.target.innerText;
      if(this.$utils.str.isEmpty(currentText)){
        this.$emit('input', '');
        event.preventDefault();
        event.stopPropagation();
      }
    }
  }
};
</script>

<style scoped>
.ce-paragraph {
  word-break: break-all; 
  line-height: 1.6em;
  outline: none;
}

.ce-paragraph p:first-of-type {
  margin-top: 0;
}

.ce-paragraph p:last-of-type {
  margin-bottom: 0;
}

[contenteditable=true]:empty:before{
  content: attr(placeholder);
  pointer-events: none;
}
</style>
