import axios from '@shared/services/api/axiosConfig.js';
import { getAuthHeaders } from '@shared/services/auth/authApiServices.js';
import { apiPathStart } from '@broker/services/api/brokerApiHelper.js'

// const debug = process.env.NODE_ENV !== 'production';

export default {
  async getInsurers(){
    const headers = await getAuthHeaders();
    const res = await axios.get(`/api/demo/insurers`, headers)
    return res;
  },
  async getProposal(proposalId){
    const headers = await getAuthHeaders();
    const res = await axios.get(`/api/demo/proposal/${proposalId}`, headers)
    return res;
  },
  async getTemplateFillMap(templateId){
    const headers = await getAuthHeaders();
    const res = await axios.get(`/api/demo/template/${templateId}/fillmap`, headers)
    return res;
  },
  async postTemplateFillMap(templateId, maps){
    const headers = await getAuthHeaders();
    const res = await axios.post(`/api/demo/template/${templateId}/fillmap`, maps, headers)
    return res;
  },
  urlPdfUpload() {
    let path = `/api/demo/pdf`;
    let fullPath = apiPathStart() +path
    return fullPath;
  },
  urlImportUpload() {
    let path = `/api/demo/import`;
    let fullPath = apiPathStart() +path
    return fullPath;
  },
}
