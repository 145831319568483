import axios from '@shared/services/api/axiosConfig.js';
import { getAuthHeaders } from '@shared/services/auth/authApiServices.js';
import {  downloadToComputer } from '@broker/services/api/brokerApiHelper.js'
const debug = process.env.NODE_ENV !== 'production';


export default {
  async getAllProposals() {
    const headers = await getAuthHeaders();
    const res = await axios.get('/api/excel/all', headers);

    if (debug) console.log('getAllProposals', res);
    
    return res;
  },
  async download(ids) {
    const headers = await getAuthHeaders();
    headers.responseType = 'arraybuffer'

    let query = ids.join('&ids=')

    const res = await axios.get(`/api/excel/report?ids=`+query, headers)
    if (debug) console.log('download',res);
    let filename = `Brokit - Export.xlsx`

    downloadToComputer(res.data, 'application/zip', filename );

    return res;
  },
}
