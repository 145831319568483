import { render, staticRenderFns } from "./VQYesNo.vue?vue&type=template&id=78f5faba&scoped=true&"
import script from "./VQYesNo.vue?vue&type=script&lang=js&"
export * from "./VQYesNo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78f5faba",
  null
  
)

export default component.exports