<template>
  <div class="form-group">
    <component :is="componentType" v-bind="proposalItem"></component>
    <h6 v-if="proposalItem.ifQ">Visible if *{{proposalItem.ifQ.option}}* selected for question: '{{elementTitle}}'</h6>
  </div>
</template>

<script>
import editHelper from '@broker/blockFormEditor/utils/proposalEditHelper.js';

// These are common across displaying and editing
import VText from '@shared/components/questions/ui/VText.vue';
import VNone from '@shared/components/questions/ui/VNone.vue';

// Specific for displaying purposes. 
// Decided to leave here so can see where these are used by doing a search
import VQShort from '@shared/components/editorQuestions/ui/VQShort.vue';
import VQYesNo from '@shared/components/editorQuestions/ui/VQYesNo.vue';
import VQLong from '@shared/components/editorQuestions/ui/VQLong.vue';
import VQTable from '@shared/components/editorQuestions/ui/VQTable.vue';
import VQOption from '@shared/components/editorQuestions/ui/VQOption.vue';
import VQDate from '@shared/components/editorQuestions/ui/VQDate.vue';
import VQCheck from '@shared/components/editorQuestions/ui/VQCheck.vue';
import VQSelect from '@shared/components/editorQuestions/ui/VQSelect.vue';
import VQUpload from '@shared/components/editorQuestions/ui/VQUpload.vue';
import VQSign from '@shared/components/editorQuestions/ui/VQSign.vue';
import VNotAllowed from '@shared/components/questions/ui/VNotAllowed.vue';
import VQCompanyHouseName from '@shared/components/editorQuestions/ui/VQCompanyHouseName.vue';
import VQCharityName from '@shared/components/editorQuestions/ui/VQCharityName.vue';
import VQNumber from '@shared/components/editorQuestions/ui/VQNumber.vue';

import VQuestionSubSection from '@broker/blockFormEditor/ui/render/VQuestionSubSection.vue';
import VSectionTitle from '@shared/components/questions/ui/VSectionTitle.vue';

export default {
  name: "EditorViewItemHolder",
  components: {
    VNone,
    VText,
    VQShort,
    VQYesNo,
    VQLong,
    VQTable,
    VQOption,
    VQDate,
    VQCheck,
    VQSelect,
    VQUpload,
    VQSign,
    VQuestionSubSection,
    VSectionTitle,
    VNotAllowed,
    VQCompanyHouseName,
    VQCharityName,
    VQNumber,
  },
  props: {
    proposalItem: Object,
    allowSections: Boolean,
  },
  computed: {
    componentType() {
      if(!this.allowSections && (this.proposalItem.type === 'section' || this.proposalItem.type === 'subsection')){
        return 'VNotAllowed'
      }

      return editHelper.component(this.proposalItem.type);
    },
    elementTitle() {
      let dependentQuestion = this.$store.getters['editor/getQuestion'](this.proposalItem.ifQ.id);
      return dependentQuestion.question;
    }
  }
};

</script>

<style scoped>
h6 {
  font-size: 85%;
  font-style: italic;
}
</style>
