<template>
  <div class="container">
    <div class="row">
      <div class="col-sm">
        <div class="lay-card mt-5 mb-5">
          <form class="mt-3" @submit.prevent="handleSubmit" novalidate>
            <div class="lay-card-top">
              <h5 class="card-title">Please enter the client's details</h5>

              <div class="form-group">
                <label for="txtName">Name</label>
                <input
                  type="text"
                  class="form-control"
                  :class="stateClass(nameValid)"
                  id="txtName"
                  placeholder="Name"
                  v-model="client.name"
                  :aria-invalid="!nameValid"
                  aria-required="true"
                />
                <div class="invalid-feedback">Please provide a name</div>
              </div>

              <div class="form-group">
                <label for="txtRecommender">Introducer (optional, for wholesale)</label>
                <multiselect
                  v-model="client.introducer"
                  :options="introducerOptions"
                  track-by="id" 
                  label="name"
                  :show-labels="false"
                  placeholder="Pick the introducer. You can add new introducers on the wholesale page"
                >
                </multiselect>
              </div>

              <div class="form-group">
                <label for="txtDesc">Description (optional)</label>
                <textarea
                  class="form-control"
                  id="txtDesc"
                  placeholder="Enter a description"
                  rows="2"
                  v-model="client.description"
                />
                <small id="txtDescHelp" class="form-text text-muted">A brief description of the client</small>
              </div>
            </div>

            <div class="lay-card-bottom">
              <VEditButton class="eb-subtle" type="Input" @click="handleCancel">Cancel</VEditButton>
              <VEditButton class="eb-success" type="Submit">Save</VEditButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

// see https://www.digitalocean.com/community/tutorials/vuejs-vue-form-handling
export default {
  components: {
    Multiselect
  },
  data() {
    return {
      client: {
        name: '',
        description: '',
        introducer: ''
      },
      isSubmitted: false,
      introducerOptions: []
    };
  },
  computed: {
    nameValid() {
      return this.client.name.length > 2 && !this.$utils.str.isEmpty(this.client.name);
    }
  },
  beforeMount() {
    this.getAllIntroducers();
  },
  methods: {
    stateClass(computedItem) {
      if (!this.isSubmitted) return '';

      return computedItem ? 'is-valid' : 'is-invalid';
    },
    handleSubmit() {
      if (this.$debug) console.log('submit client');
      this.isSubmitted = true;

      if (this.nameValid) {
        if(this.client.introducer){
          this.client.introducerId = this.client.introducer.id;
        }

        this.$api.company.postNewCompany(this.client).then(res => {
          this.$router.push(`/broker/client/${res.data}/proposals`);
        });
      }
    },
    handleCancel() {
      this.$router.push(`/broker/clients`);
    },
    getAllIntroducers() {
      if (this.$debug) console.log('getAllIntroducers');
      this.$api.wholesale.getAllIntroducersForDropdown().then(res => {
        this.introducerOptions = res.data;
      });
    },
  }
};
</script>

<style></style>
