import {
  createItem,
  selectableQuestionsRecursively,
  findParent,
  updateRecursive,
  deleteRecursively,
  cleanForSave,
  flattenSections,
  expandSections,
  findMaxId
} from '@broker/store/formEditorStoreFns.js';
import { findRecursively } from '@shared/store/utils/recursiveFind.js';


import { $api } from '@broker/services/api/apis.js';

let networkForm = {};
let changes = [];
let undoMode = false;
let count = 0;

const debug = process.env.NODE_ENV !== 'production';
function initialState() {
  return {
    saving: false,
    active: 0,
    nextId: 0,
    form: {},
    dbId: 0, // default 0 means new
    answers: {},
    displayTitle: '',
    formName: '',
    mode: null,
    formNotExistError: false
  };
}
const state = initialState();

export const getters = {
  isActive: state => index => {
    return state.active === index;
  },
  getAllSelectableQuestions: state => id => {
    let list = [];
    selectableQuestionsRecursively(state.form, list, id, false);
    let index = list.findIndex(x => x.value === id);
    return list.slice(0, index);
  },
  getQuestion: (state) => (id) => {
    return findRecursively(state.form, id);
  },
  // if form has alread set answer
  answer: state => id => {
    return state.answers[id];
  }
};

export const mutations = {
  resetState(state) {
    if (debug) console.log('resetState');
    Object.assign(state, initialState());
  },
  setMode(state, mode) {
    if (debug) console.log('setMode', mode);
    state.mode = mode;
  },
  setDbId(state, id) {
    state.dbId = id;
  },
  setFullForm(state, payload) {
    if (debug) console.log('setFullForm triggered with ', payload);
    state.form = payload.form;

    // flatten sections
    state.form.sections = flattenSections(state.form.sections);
    if (debug) console.log('flattened Sections  ', state.form.sections);
    

    state.nextId = payload.nextElementId;
    state.answers = payload.answers || {};
    state.displayTitle = payload.meta.displayTitle;
    state.formName = payload.meta.name;

    // Note: the root element of the form should always be 0. This enforces it
    state.form.id = 0;
  },
  setFormLoadError(state) {
    if (debug) console.log('setFormLoadError');
    state.formNotExistError = true;
  },
  setFormActive(state, id) {
    if (debug) console.log('setFormActive triggered with', id);

    internalSetActive(state, id);
  },
  updateTitle(state, value) {
    if (debug) console.log('updateTitle', value);
    state.displayTitle = value;
  },
  updateFormName(state, value) {
    if (debug) console.log('updateFormName', value);
    state.formName = value;
  },
  addFormItem(state, payload) {
    if (debug) console.log('addFormItem', payload);

    let parent = findRecursively(state.form, payload.parentId);
    if (parent.sections) {
      let newItem = createItem(state);
      if(payload.val){
        newItem.question = payload.val;
      }
      if (payload.atStart) {
        parent.sections.unshift(newItem);
      } 
      else {
        parent.sections.push(newItem);
      }

      internalSetActive(state, newItem.id);
    }
  },
  addAfterItem(state, id) {
    if (debug) console.log('addAfterItem', id);

    let newItem = createItem(state);
    internalAddAfterItem(state, id, newItem);
  },  
  setFormItem(state, payload) {
    if (debug) console.log('setFormItem triggered with', payload.id, payload.data);

    let updated = updateRecursive(state.form, payload.id, payload.data);
    state.form = updated;
  },
  setSections(state, payload) {
    // could use setFormItemAbove but this keeps things cleaner
    if (debug) console.log('setSections triggered with', payload);

    state.form.sections = payload;
  },
  setSubSections(state, payload) {
    // could use setFormItemAbove but this keeps things cleaner
    if (debug) console.log('setSubSections triggered with', payload);

    var subsectionQ = state.form.sections.find(x => x.id === payload.id && x.type === 'subsection');
    subsectionQ.sections = payload.sections;
  },
  deleteFormItem(state, id) {
    if (debug) console.log('deleteFormItem');

    deleteRecursively(state.form, id);
  },
  rawFormUpdate(state, payload){
    if (debug) console.log('rawFormUpdate triggered with', payload);

    state.form = cleanForSave(payload);

    state.form.sections = flattenSections(state.form.sections);
    if (debug) console.log('flattened Sections  ', state.form.sections);

    state.form.id = 0; // Note: the root element of the form should always be 0. This enforces it
    state.nextId = findMaxId(state.form) + 1;
    
    if (debug) console.log('state.nextId: ', state.nextId);
  },
  newTemplate(state) {
    state.dbId = 0;
  },
  isSaving(state, value) {
    state.saving = value;
  }
};

const actions = {
  async UPDATE({commit, dispatch}, payload){
    commit(payload.commit, payload.value);
    if(!undoMode){
      changes.push({
        method: payload.commit,
        payload: JSON.parse(JSON.stringify(payload.value))
      });
    }
    if(count === 10){
      commit('isSaving', true);
      await dispatch('SAVE');
      commit('isSaving', false);
      count = 0;
    } else {
      count += 1;
    }
  },
  UNDO({ commit }){
    undoMode = true;
    let inputForm = JSON.parse(networkForm);
    commit('setFullForm', inputForm)
    changes.pop();
    for (let index = 0; index < changes.length; index++) {
      const element = changes[index];
      commit(element.method, element.payload);
    }
    undoMode = false;
  },
  INIT_EDIT_TEMPLATE({ commit }, formId) {
    if (debug) console.log('INIT_EDIT_TEMPLATE:', formId);

    commit('resetState');
    commit('setMode', 'Template');
    commit('setDbId', formId);

    $api.template
      .getTemplate(formId)
      .then(res => {
        networkForm = JSON.stringify(res.data);
        changes = []
        commit('setFullForm', res.data);
      })
      .catch(err => {
        if (err.response && err.response.status == 404) {
          commit('setFormLoadError');
        }
      });
  },
  INIT_NEW_TEMPLATE({ commit }) {
    if (debug) console.log('INIT_NEW_TEMPLATE');

    commit('resetState');
    commit('setMode', 'Template');

    $api.template
      .getDefault()
      .then(res => {
        commit('setFullForm', res.data);
      })
      .catch(err => {
        if (err.response && err.response.status == 404) {
          commit('setFormLoadError');
        }
      });
  },
  INIT({ commit }, form) {
    if (debug) console.log('INIT:', form);

    commit('resetState');
    commit('setMode', 'Template');
    commit('setFullForm', form);
  },
  async SAVE({ commit, state }) {
    if (debug) console.log('SAVE', state.dbId);

    let cleaned = cleanForSave(state.form);
    let nextElemId = findMaxId(state.form) + 1;
    
    // expand sections again before save
    let expanedSections;
    [expanedSections, nextElemId] = expandSections(cleaned.sections, nextElemId);
    cleaned.sections = expanedSections;
    state.nextElemId = nextElemId;

    let data = {
      nextElementId: nextElemId,
      form: cleaned,
      displayTitle: state.displayTitle,
      formName: state.formName
    };
    
    let res = await $api.template.postEditTemplate(state.dbId, data);
    commit('setDbId', res.data);
  }
};

function internalSetActive(state, id) {
  if (debug) console.log('internalSetActive triggered with ', id);
  state.active = id;
}

function internalAddAfterItem(state, afterThisId, item) {
  let parent = findParent(state.form, afterThisId);

  if (parent != null) {
    var index = parent.sections.findIndex(x => x.id === afterThisId);
    parent.sections.splice(index + 1, 0, item);

    internalSetActive(state, item.id);
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
