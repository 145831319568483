<template>
  <div v-if="isProd">
    <Page404 :errorText="featureDisabled" :errorCode="errorCode"></Page404>
  </div>
  <div v-else class="container">
    <div class="lay-options-both">
      <div>
        <VEditButton class="eb-fade" @click="onBack">Back</VEditButton>
      </div>
      <div>
        <VEditButton class="eb-success" @click="updateDb">Save</VEditButton>
      </div>
    </div>
    <hr />
    <div class="pt-2">
      <VEditButton class="eb-sec mb-1" @click="addRow">Add Row</VEditButton>
      <VEditButton class="eb-sec mb-1" @click="removeSelectedRows" :disabled="isSelected == false">Remove Row</VEditButton>

      <ag-grid-vue
        style="width: 100%; height: 75vh"
        class="ag-theme-alpine"
        :gridOptions="gridOptions"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        v-model="rowData"
        @rowMultiSelectWithClick="true"
        @selection-changed="onSelectionChanged"
      >
      </ag-grid-vue>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import { mapGetters } from 'vuex';
import questionsHelper from '@shared/components/questions/utils/questionsHelper.js';


export default {
  components: {
    AgGridVue
  },
  props: {
    templateId: Number
  },
  data() {
    return {
      gridOptions: null,
      defaultColDef: null,
      rowData: null,
      selectedRows: null,
      isSelected: false,
      templateQuestions: [],
      columnDefs:null,
    };
  },
  computed: {
    ...mapGetters(['isProd'])
  },
  async beforeMount() {
    this.gridOptions = {};

    this.defaultColDef = {
      resizable: true,
      editable: true
    };

    this.init();
  },
  methods: {
    async init(){
      let mapRes = await this.$api.demo.getTemplateFillMap(this.templateId)
      this.rowData = mapRes.data;

      let templateRes = await this.$api.template.getTemplate(this.templateId);
      this.templateQuestions = questionsHelper.extractQuestions(templateRes.data.form);
      this.setupGrid();
    },
    updateDb() {
      let isEmpty = this.$utils.str.isEmpty;
      let toSave = [];
      for (let index = 0; index < this.rowData.length; index++) {
        const element = this.rowData[index];
        if (element.questionId && !isEmpty(element.mapping)) {
          element.questionId = parseInt(element.questionId);
          toSave.push(element);
        }
      }
      this.$api.demo.postTemplateFillMap(this.templateId, toSave);
    },
    addRow() {
      var newItem = [{}];
      this.gridOptions.api.applyTransaction({
        add: newItem
      });
    },
    removeSelectedRows() {
      var selectedData = this.gridOptions.api.getSelectedRows();
      this.gridOptions.api.applyTransaction({ remove: selectedData });
      this.isSelected = false;
    },
    onSelectionChanged(params) {
      var selectedRows = params.api.getSelectedRows();
      if (selectedRows.length > 0) {
        this.isSelected = true;
      } else {
        this.isSelected = false;
      }
    },
    onBack() {
      this.$router.push('/broker/formTemplates');
    },
    lookupById(id){
      if(!id) return null;

      return this.templateQuestions.find(x => x.id==id).question;
    },
    lookupByQuestion(question) {
      if (!question) return null;

      return this.templateQuestions.find(x => x.question==question).id;
    },
    setupGrid(){
      const vue = this;
      
      this.columnDefs = [
      { 
        field: 'questionId', 
        flex: 4,
        headerName: 'Question',
        cellEditor : 'agSelectCellEditor',
        cellEditorParams: {
          values: this.templateQuestions.map(x => x.id)    
        },
        valueFormatter: function (params) {
            return vue.lookupById(params.value);
        },
        valueParser: function (params) {
            return vue.lookupByQuestion(params.newValue);
        }
      },
      {
        field: 'mapping',
        cellEditor: 'agSelectCellEditor',
        flex: 2,
        cellEditorParams: {
          values: [
            'CompaniesHouse.CompanyName',
            'CompaniesHouse.CompanyNumber',
            'CompaniesHouse.SicCodes',
            'CompaniesHouse.IncorporatationDate',
            'CompaniesHouse.IsActive',
            'CompaniesHouse.RegisteredOfficeAddress',
            'CompaniesHouse.PostCode',
            'CompaniesHouse.CompanyType',
            'CompaniesHouse.Country',
            'CompaniesHouse.City',

            'CharityCommission.Name',
            'CharityCommission.RegistrationNumber',
            'CharityCommission.RegisteredCompanyNumber',
            'CharityCommission.Address',
            'CharityCommission.Postcode',
            'CharityCommission.Website',
            'CharityCommission.Email',
            'CharityCommission.Telephone',
            'CharityCommission.DateEstablished',
            'CharityCommission.Organisation',
            'CharityCommission.OrganisationType',
            'CharityCommission.WarnIsDeregistered',

            'CharityCommission.Staff.TotalStaff',
            'CharityCommission.Staff.Trustees',
            'CharityCommission.Staff.Employees',
            'CharityCommission.Staff.Volunteers',
            'CharityCommission.Staff.TrusteeNames',
            'CharityCommission.AreaOfOperation.Regions',
            'CharityCommission.Goals.CharitableObjects',
            'CharityCommission.Goals.Activities',
            'CharityCommission.Financials.GrossIncome',
            'CharityCommission.Financials.TotalExpenditure'
          ]
        }
      }
    ];
    }
  }
};


</script>

<style scoped></style>
