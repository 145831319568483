<template>
  <div>
    <div v-if="isLoadError || isLoading" class="container">
      <PageLoading loadingText="Loading... Please wait..." v-if="isLoading"></PageLoading>
      <Page404 v-else :errorText="errMsg" :errorCode="errStatus"></Page404>
    </div>
    <div v-else class="container">
      <div class="lay-options-both">
        <div>
          <VEditButton class="eb-fade" @click="onBack">Back</VEditButton>
        </div>
        <div>
          <VEditButton class="eb-sec" @click="onAddProposalForm">Add Application Form</VEditButton>
        </div>
      </div>
      <hr />
      <div class="row text-center pt-3">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <VHeaderEdit :text="companyName" :allowEmpty="false" @text-updated="onClientNameUpdated"></VHeaderEdit>
        </div>
        <div class="col-md-3"></div>
      </div>
      <div v-if="proposals.length > 0">
        <div class="list-group pt-5">
          <ProposalListItem
            v-for="proposal in activeProposals"
            :key="proposal.id"
            v-bind="proposal"
            @delete="onProposalDelete"
            @renewal="onProposalRenew"
            @activate="onProposalActivate"
            @draft="onProposalDraft"
            @lock="onProposalLock"
            @download="onDownloadProposal"
            @rename="onProposalNameUpdated"
            @importAnswers="onImportAnswers"
          >
          </ProposalListItem>
        </div>
        <div v-if="lockedProposals.length > 0">
          <div class="row text-center pt-5">
            <div class="col-sm">
              <h5>Locked Application</h5>
              <p>
                <small>These can no longer be updated and are not accessible by the client.</small>
              </p>
            </div>
          </div>
          <div class="list-group pt-1">
            <ProposalListItem
              v-for="proposal in lockedProposals"
              :key="proposal.id"
              v-bind="proposal"
              @download="onDownloadProposal"
              @unlock="onUnlock"
              @copy="onProposalRenew"
              @rename="onProposalNameUpdated"
            >
            </ProposalListItem>
          </div>
        </div>
      </div>
      <div v-else class="text-center">
        <h5 style="margin-top:5rem">No Application Forms</h5>
        <p><small>Maybe add one eh?</small></p>
        <VEditButton class="eb-sec mt-5" @click="onAddProposalForm">Add Application Form</VEditButton>
      </div>
      <hr style="margin-top:5rem" />
      <div class="lay-options-right" style="margin-bottom:1rem">
        <VEditButton class="eb-fade text-danger" @click="onClientDelete">Delete Client</VEditButton>
      </div>
    </div>
    <v-dialog />
  </div>
</template>

<script>
import ProposalListItem from '@broker/clientProposals/ui/ProposalListItem.vue';
import { publishedProposalDialog, deleteProposalDialog, deleteClientDialog } from '@broker/clientProposals/ui/proposalDialogs.js';

export default {
  components: {
    ProposalListItem
  },
  props: ['clientId'],
  data() {
    return {
      companyName: '',
      proposals: [],
      isLoadError: false,
      isLoading: true,
      errStatus: '',
      errMsg: ''
    };
  },
  created() {
    this.fetchData();
  },
  watch: {
    $route: 'fetchData'
  },
  computed: {
    activeProposals() {
      return this.proposals.filter(x => {
        return x.state === 'Active' || x.state === 'Draft' || x.state === 'Completed';
      });
    },
    lockedProposals() {
      return this.proposals.filter(x => {
        return x.state === 'Lock';
      });
    }
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      this.$api.company
        .getProposalsForClient(this.clientId)
        .then(res => {
          this.companyName = res.data.name;
          this.proposals = res.data.proposals;
        })
        .catch(err => {
          this.errMsg = err.message;
          this.isLoadError = true;
          if (err.response && err.response.status) {
            this.errStatus = err.response.status;
            if (this.errStatus == '404') this.errMsg = 'Client was not found';
          }
        })
        .then(() => {
          // Always executed
          this.isLoading = false;
        });
    },
    onBack() {
      this.$router.push('/broker/clients');
    },
    onAddProposalForm() {
      this.$router.push({ path: `/broker/client/${this.clientId}/addForm` });
    },
    onProposalDelete(id) {
      deleteProposalDialog(this.$modal, id, () => this.fetchData());
    },
    onProposalRenew(id) {
      this.$router.push({ path: `/broker/proposal/${id}/renewal` });
    },
    onImportAnswers(id){
      this.$router.push({ path: `/broker/proposal/${id}/importAnswers` });
    },
    async onProposalActivate(id) {
      await this.$api.proposal.postChangeState(id, 'Active');
      
      const proposal = this.activeProposals.filter(x => x.id === id)[0];
      publishedProposalDialog(this.$modal, proposal.fullLink, proposal.clientLink, () => this.fetchData());
    },
    onProposalDraft(id) {
      this.changeState(id, 'Draft');
    },
    onProposalLock(id) {
      this.changeState(id, 'Lock');
    },
    onUnlock(id) {
      this.changeState(id, 'Active');
    },
    changeState(id, newState) {
      this.$api.proposal.postChangeState(id, newState).then(() => this.fetchData());
    },
    onDownloadProposal(id) {
      var proposalName = this.proposals.filter(x => x.id === id)[0].name;
      this.$api.proposal.download(id, proposalName, this.companyName);
    },
    // TODO: Should move this to a component that is just delete button and this.
    onClientDelete() {
      if (this.$debug) console.log('Deleting client');

      deleteClientDialog(this.$modal, this.$toast, this.companyName, this.clientId, () => {
        this.$router.push('/broker/clients');
      });
    },
    onClientNameUpdated(name) {
      if (this.$debug) console.log('Updating client name');

      this.$api.company.postEditCompany(this.clientId, name);
    },
    onProposalNameUpdated(item){
      if (this.$debug) console.log('Updating propsal name', item);

      this.$api.proposal.postRename(item.id, item.name);
    },
  }
};
</script>

<style scoped>
.header {
  margin-top: 50px;
  margin-bottom: 20px;
}
.playwright-formlink {}
</style>
