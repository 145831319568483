
export function mergeCurrentWithPastedTable(text, currentData, coord) {
  console.log('mergeCurrentWithPastedTable')
  const clipRows = textAsGrid(text);
  const maxColLen = getMaxNestedArrLen(clipRows);

  const noOfRows = Math.max(currentData.length, clipRows.length + coord.row);
  const noOfCols = Math.max(currentData[0].length, maxColLen + coord.col);

  let mergedArr = merge(noOfRows, noOfCols, currentData, clipRows, coord);

  return mergedArr;
}

function merge(noOfRows, noOfCols, currentData, clipRows, offset) {
  let mergedArr = createArray(noOfRows, noOfCols);

  for (let x = 0; x < noOfRows; x++) {
    for (let y = 0; y < noOfCols; y++) {
      mergedArr[x][y] = safeGetAt(currentData, x, y);
      // override with paste data if present
      let pasteData = safeGetAt(clipRows, x-offset.row, y-offset.col);
      if (pasteData !== '') {
        mergedArr[x][y] = pasteData;
      }
    }
  }

  return mergedArr;
}

function textAsGrid(text) {
  let clipRows = text.split(/\r\n|\r|\n/g);

  // split rows into columns
  for (let i = 0; i < clipRows.length; i++) {
    clipRows[i] = clipRows[i].split(String.fromCharCode(9));
  }
  return clipRows;
}

function getMaxNestedArrLen(source) {
  let largestArr = source.reduce((max, cur) => (cur.values.length > max.values.length ? cur : max), source[0]);
  return largestArr.length;
}

function createArray(rows, cols) {
  let arr = new Array(rows);
  for (var i = 0; i < arr.length; i++) {
    arr[i] = new Array(cols);
  }
  return arr;
}

function safeGetAt(arr, x, y) {
  if (x < arr.length && x >= 0) {
    if (y < arr[x].length && y >= 0) {
      return arr[x][y];
    }
  }
  return '';
}

