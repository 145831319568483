export default function deleteTemplateDialog($modal, $api, id, onDeleteCallBack){
  $modal.show('dialog', {
    title: 'Delete Template',
    text: 'This will delete the template. Any proposals created from this template will remain unaffected. You cannot undo this action, Are you sure?',
    buttons: [
      {
        title: 'Yes',
        handler: async () => {
          $modal.hide('dialog');

          await $api.template.deleteTemplate(id)

          onDeleteCallBack();
        }
      },          
      {
        title: 'Cancel',
        handler: () => {
          $modal.hide('dialog')
        }
      },
    ]
  });
}