<template>
  <div>
    <p>
      Copied To clipboard! You can also access the page <a class="cool-white-link" :href="link"  target="_blank">here</a>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    link: String,
  },
};
</script>