import { $api } from '@broker/services/api/apis.js';
import { isEmpty } from '@shared/services/utils/stringUtils.js';
import copyTextToClipboard from '@broker/clientProposals/utils/copyTextToClipboard.js'

export function publishedProposalDialog($modal, swiftlink, clientLink, onOkHandler){
  let text = "<p>You should inform the client that they can now fill out the proposal using this link:</p>"
  + `<p class='text-center playwright-formlink' style='border: 1px solid #000;'>${swiftlink}</p>`
  + "<p>This has also been copied to your clipboard.</p>"

  let hasClientLink = !isEmpty(clientLink)
  if(hasClientLink){
    text = "<p>You should inform the client that they can now fill out the proposal using one of these links:</p>"
    + `<p class='text-center' style='border: 1px solid #000;'>${clientLink}</p>`
    + "<p>This has also been copied to your clipboard. Alternative link </p>"
    + `<p class='text-center' style='border: 1px solid #000;'>${swiftlink}</p>`
  }

  $modal.show('dialog', {
    title: 'Published',
    text: text,
    buttons: [
      {
        title: 'Ok',
        handler: () => {
          $modal.hide('dialog')
          onOkHandler();
        }
      }
    ]
  });

  copyTextToClipboard(hasClientLink ? clientLink : swiftlink);
}

export function deleteProposalDialog($modal, id, onDeleteHandler){
  $modal.show('dialog', {
    title: 'Delete Proposal',
    text: 'This will delete the proposal. Are you sure?',
    buttons: [
      {
        title: 'Yes',
        handler: async () => {
          $modal.hide('dialog')

          $api.proposal.deleteProposal(id).then(() => { 
            onDeleteHandler();
          })
        }
      },          
      {
        title: 'Cancel',
        handler: () => {
          $modal.hide('dialog')
        }
      },
    ]
  });
}

export function deleteClientDialog($modal, $toast, companyName, clientId, onDeleteHandler){
  $modal.show('dialog', {
    title: 'Delete: ' + companyName,
    text: 'This will delete this client, all their proposals and any uploaded documents or answers. THIS IS IRREVERSIBLE. Are you sure?',
    buttons: [
      {
        title: 'Delete Client',
        handler: () => {
          $modal.hide('dialog')

          $toast('Deleting. This may take a while. Please wait...', {
            timeout: 2000,
            icon: "fas fa-rocket",
            hideProgressBar: true,
          });

          $api.company.deleteCompany(clientId).then(function(){
            onDeleteHandler();
          });
        }
      },          
      {
        title: 'Cancel',
        handler: () => {
          $modal.hide('dialog')
        }
      },
    ]
  });
}
