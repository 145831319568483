<template>
  <div>
    <b-form-checkbox :checked='isShowOnQuestion' @input='onShownOnQuestion' switch>
      Show On
    </b-form-checkbox>
    <b-form-select v-if='isShowOnQuestion' v-model='selected' :options='questionsChoices'></b-form-select>
    <b-form-select v-if='isShowOnQuestion' :value='selectedOpt' @input='onAnswerSelected' :options='questionsOpts'></b-form-select>
  </div>
</template>

<script>
const SET_FORM_ITEM = 'editor/setFormItem';

export default {
  props: {
    id: Number,
    ifQ: Object
  },
  data() {
    return {
      isShowOnQuestion: false,
      selected: null,
      selectedOpt: null
    };
  },
  computed: {
    questionsChoices() {
      let defaultVal = {value:null, text:'Please select a question', disabled:true };
      let allQuestionsWithOptions = this.$store.getters['editor/getAllSelectableQuestions'](this.id);

      let questions = [defaultVal, ...allQuestionsWithOptions];
      return questions;
    },
    questionsOpts() {
      let defaultVal = {value:null, text:'Please select an answer', disabled:true};
      if (!this.selected) {
        return [defaultVal];
      }

      let dependentQuestion = this.$store.getters['editor/getQuestion'](this.selected);
      if (dependentQuestion.options) {
        return [defaultVal, ...dependentQuestion.options]
      } else {
        return [defaultVal, 'Yes', 'No'];
      }
    }
  },
  created() {
    if (this.ifQ) {
      this.isShowOnQuestion = true;
      this.selected = this.ifQ.id;
      this.selectedOpt = this.ifQ.option;
    }else{
      this.isShowOnQuestion = false;
      this.selected = null;
      this.selectedOpt= null;
    }
  },
  methods:{
    onShownOnQuestion(value) {
      if(this.$debug) console.log('onShownOnQuestion', value)
      this.isShowOnQuestion = value;
      if (!this.isShowOnQuestion){
        let payload = { id: this.id, data: { ifQ: null } };
        this.$store.commit(SET_FORM_ITEM, payload);
      }

      this.$emit("showon", value)
    },
    onAnswerSelected(selAnswer) {
      let payload = { id: this.id, data: { ifQ: { id: this.selected, option: selAnswer }}};
      this.$store.commit(SET_FORM_ITEM, payload);
    },
  }
};
</script>

<style>
</style>