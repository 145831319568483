<template>
  <fieldset>
    <legend tabindex='-1' class='question-label col-form-label pt-0  bv-no-focus-ring'>{{question}}</legend>
    <div :id='elementId' role='radiogroup' tabindex='-1'>
      <div v-for='(option, index) in options' :key='index' tabindex='-1' class='bv-no-focus-ring custom-control custom-radio' >
        <input :id='optionId(index)' type='radio' :value='option' :checked='selected' class='custom-control-input' :name='elementId' autocomplete='off' />
        <label :for='optionId(index)' class='custom-control-label'>
          <span>{{option}}</span>
        </label>
      </div>
    </div>
  </fieldset>
</template>

<script>

export default {
  props: {
    id: Number,
    question: String,
    options: Array,
    type: String,
  },
  computed: {
    elementId() {
      return this.type + this.id;
    },
    selected() {
      return this.$store.getters['editor/answer'](this.id);
    }
  },
  methods: {
    optionId(idx) {
      return this.elementId + '_' + idx;
    }
  }
};
</script>

<style scoped>
</style>