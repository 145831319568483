<template>
  <div>
    <div class="row text-center pt-3">
      <div class="col-sm">
        <h3>Pick a Client</h3>
      </div>
    </div>
    <div class="list-group pt-3 pb-3">
      <ag-grid-vue
        style="width: 100%; height: 30vh"
        class="ag-theme-alpine"
        :gridOptions="gridOptions"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="companies"
        rowSelection='single'
        @selection-changed="onSelectionChanged"
      >
      </ag-grid-vue>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';

export default {
  components: {
    AgGridVue
  },
  data() {
    return {
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      defaultColDef: null,
      columnDefs: null
    };
  },
  props: {
    companies: {
      type: Array,
      default: () => []
    },
  },
  beforeMount() {
    this.gridOptions = {};
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      floatingFilter: true,
    };

    this.columnDefs = [
      {
        headerName: 'Name',
        field: 'name',
        flex: 1,
        minWidth: 250,
      },
    ];
  },
  methods: {
    onSelectionChanged() {
      const selectedRows = this.gridOptions.api.getSelectedRows();
      if(this.$debug) console.log('onSelected', selectedRows)
      const companyId = selectedRows[0].id;
      this.$emit('selectedCompany', companyId)      
    }
  },
}
</script>

<style scoped>

</style>