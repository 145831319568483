<template>
    <VEditButton v-if="!active" style="margin-left:0; max-height:100px;" class="w-150 eb-sec">{{ typeName }}</VEditButton>
    <multiselect v-else class="w-150" :options="types" :searchable="false" v-model="selectedType" :show-labels="false" :allow-empty="false">
    </multiselect>
</template>

<script>

import Multiselect from 'vue-multiselect';
import proposalEditHelper from '@broker/blockFormEditor/utils/proposalEditHelper.js';

const SET_FORM_ITEM = 'editor/setFormItem';

export default {
  components: { Multiselect },
  name: 'TypeSelector',
  props: {
    type: String,
    id: Number,
    active: Boolean
  },
  computed: {
    typeName() {
      return proposalEditHelper.name(this.type);
    },
    selectedType: {
      get() {
        return this.typeName;
      },
      set(value) {
        var type = proposalEditHelper.type(value);
        var payload = { id: this.id, data: { type: type } };
        this.$store.commit(SET_FORM_ITEM, payload);
      }
    },
    types() {
      return proposalEditHelper.getComponents();
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.multiselect__content-wrapper {
  width: 170%;
}
</style>
