<template>
  <div :class="isActiveClass" class="sub-section-holder">
    <div @click="notifyComponentClicked">
      <div class="row">
        <div class="col-sm-1"></div>
        <div class="col-sm-10 text-center d-flex flex-column justify-content-center handle-holder">
          <i class="fas fa-grip-horizontal handle"></i>
        </div>
        <div class="col-sm-1 text-center d-flex flex-column justify-content-center delete-holder">
          <i class="far fa-times-circle delete-icon cool-danger" @click="onDelete"></i>
        </div>
      </div>
      <div class="row">
        <div class="col" style="display: flex">
          <TypeSelector :type="type" :id="id" :active="isActive"></TypeSelector>
          <div class="ml-2">
            <VContentEditableText :active="isActive" v-model="questionText" @newEntry="onNewEntry"></VContentEditableText>
          </div>
        </div>
      </div>
    </div>
    <draggable :value="elements" handle=".handle" v-bind="dragOptions" @input="emitter">
      <div v-if="elements.length === 0" @click="onNewEntry()" class="sub-gap" slot="header">
        <i>Subsection empty, add or drop an element here...</i>
      </div>
      <div v-for="element in elements" :key="element.id">
        <EditorBlock class="item-sub" v-bind="element"></EditorBlock>
      </div>
      <div class="row" @click="notifyComponentClicked">
        <div class="col-sm-11"></div>
        <div class="col-sm-1 text-center d-flex flex-column justify-content-center add-holder">
          <i class="ml-6 fas fa-plus-circle cool-green add-icon" @click="onAddAfter"></i>
        </div>
      </div>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

import EditorBlock from '@broker/blockFormEditor/ui/editor/blocks/EditorBlock.vue';
import VContentEditableText from '@broker/blockFormEditor/ui/editor/contentEditable/VContentEditableText.vue';
import TypeSelector from '@broker/blockFormEditor/ui/editor/typeSelector/TypeSelector.vue';

import scrollMeTo from '@shared/components/stepper/utils/scrollMeTo.js';

const IS_ACTIVE = 'editor/isActive';

export default {
  components: {
    draggable,
    EditorBlock,
    VContentEditableText,
    TypeSelector,
  },
  name: 'SubSectionBlock',
  props: {
    id: Number,
    text: String,
    sections: Array,
    type: String,
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
    questionText: {
      get() {
        return this.text;
      },
      set(value) {
        this.update(value);
      },
    },
    elements: {
      get() {
        return this.sections;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    isActiveClass() {
      return this.$store.getters[IS_ACTIVE](this.id) ? 'component-active' : 'component-inactive';
    },
    isActive() {
      return this.$store.getters[IS_ACTIVE](this.id);
    },
  },
  methods: {
    notifyComponentClicked() {
      this.$store.commit('editor/setFormActive', this.id);
      scrollMeTo.on(this.type + this.id);
    },
    emitter(value) {
      if (this.$debug) console.log('emitter', this.id, value);
      this.$emit('input', value);
    },
    onNewEntry(val) {
      let payload = { parentId: this.id, atStart: true, val: val };
      this.$store.dispatch('editor/UPDATE', {commit: 'addFormItem', value: payload});
    },
    onAddAfter() {
      this.$store.dispatch('editor/UPDATE', {commit: 'addAfterItem', value: this.id});
    },
    onAdd() {
      let payload = { parentId: this.id };
      this.$store.dispatch('editor/UPDATE', {commit: 'addFormItem', value: payload});
    },
    update(value) {
      let payload = { id: this.id, data: { text: value } };
      this.$store.dispatch('editor/UPDATE', {commit: 'setFormItem', value: payload});
    },
    onDelete() {
      this.$store.dispatch('editor/UPDATE', {commit: 'deleteFormItem', value: this.id});
    },
  },
};
</script>

<style scoped>
.item-sub {
  margin: 0 0 0 24px;
}
.btn-sub {
  margin: 0 0 0 62px;
}

.handle {
  display: none;
}
.sub-section-holder:hover .handle {
  display: block;
}

.handle-holder {
  height: 32px;
  cursor: move;
}

.delete-holder {
  height: 32px;
}
.delete-icon {
  display: none;
}
.sub-section-holder:hover .delete-icon {
  display: block;
  cursor: pointer;
}

.component-active {
  background-color: #f1f1f1;
}

.sub-gap {
  text-align: center;
  height: 60px;
}
.sub-gap:hover {
  cursor: pointer;
  text-decoration: underline;
}

.add-holder {
  height: 32px;
}
.add-icon {
  display: none;
}
.sub-section-holder:hover .add-icon {
  display: block;
  cursor: pointer;
}

</style>
