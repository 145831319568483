<template>
  <div :id="elementId">
    <label class="question-label">{{ question }}</label>
    <ag-grid-vue
      style="width: 100%;"
      class="qtable ag-theme-alpine"
      domLayout="autoHeight"
      :gridOptions="gridOptions"
      :columnDefs="columns"
      :defaultColDef="defaultColDef"
      :rowData="tableData"
      @column-resized="onColumnResized"
    >
    </ag-grid-vue>
    <div v-if="isAddRowsEnabled">
      <VEditButton class="eb-sec mt-1" disabled >Add Row</VEditButton>
      <VEditButton class="eb-sec mt-1" disabled>Remove Row</VEditButton>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';

export default {
  components: {
    AgGridVue
  },
  props: {
    id: Number,
    question: String,
    help: String,
    columns: Array,
    tableData: Array,
    type: String,
    isAddRowsEnabled: Boolean
  },
  data() {
    return {
      gridOptions: null,
      defaultColDef: null,
      rowData: null
    };
  },
  computed: {
    elementId() {
      return this.type + this.id;
    }
  },
  beforeMount() {
    this.gridOptions = {};

    this.defaultColDef = {
      resizable: true,
      editable: false,
      autoHeight: true,
      wrapText: true
    };
  },
  mounted() {
    this.gridOptions.rowHeight = 35;
    this.gridOptions.api.sizeColumnsToFit();
  },
  methods: {
    copyRowData(data) {
      return data.map(datum => {
        return {
          ...datum
        };
      });
    },
    onColumnResized(params) {
      params.api.resetRowHeights();
    }
  }
};
</script>

<style>
.qtable .ag-cell:hover {
  cursor: text !important;
}
.qtable .ag-cell {
  cursor: text !important;
  font-size: 14px;
  line-height: 26px !important;
}

.qtable .ag-center-cols-clipper,
.qtable .ag-center-cols-container {
  min-height: 10px !important;
}
</style>
