import { render, staticRenderFns } from "./VContentEditableText.vue?vue&type=template&id=044dd4b8&scoped=true&"
import script from "./VContentEditableText.vue?vue&type=script&lang=js&"
export * from "./VContentEditableText.vue?vue&type=script&lang=js&"
import style0 from "./VContentEditableText.vue?vue&type=style&index=0&id=044dd4b8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "044dd4b8",
  null
  
)

export default component.exports