<template>
  <div class="pt-2">
    <VHeaderEdit
      :text="displayTitle"
      :allowEmpty="false"
      @text-updated="onUpdatedText"
      placeholder="Enter a title that will be displayed to respondents"
    ></VHeaderEdit>

    <VEditButton class="w-150 eb-prim" @click="onAdd">Add</VEditButton>

    <draggable :value="sections" @input="applyReorder" handle=".handle" v-bind="dragOptions">
      <div v-for="element in sections" :key="element.id">
        <SubSectionBlock
          v-if="element.type === 'subsection'"
          v-bind="element"
          @input="applySubsectionReorder(element.id, $event)"
        ></SubSectionBlock>
        <EditorBlock v-else v-bind="element"></EditorBlock>
      </div>
    </draggable>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import draggable from 'vuedraggable';
import EditorBlock from '@broker/blockFormEditor/ui/editor/blocks/EditorBlock.vue';
import SubSectionBlock from '@broker/blockFormEditor/ui/editor/blocks/SubSectionBlock.vue';

const UPDATE_TITLE = 'editor/updateTitle';
const ADD_FORM_ITEM = 'editor/addFormItem';
const SET_SECTIONS = 'editor/setSections';
const SET_SUBSECTION = 'editor/setSubSections';

export default {
  components: {
    draggable,
    EditorBlock,
    SubSectionBlock
  },
  name: 'FormEditor',
  props: {
    sections: Array
  },
  computed: {
    ...mapState('editor', ['displayTitle']),
    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      };
    },
    elements: {
      get() {
        return this.sections;
      },
      set(value) {
        if (this.$debug) console.log('set element', value);
        this.applyReorder(value)
      }
    }
  },
  methods: {
    onUpdatedText(value) {
      if (this.$debug) console.log('onUpdatedText', value);
      this.$store.commit(UPDATE_TITLE, value);
    },
    onAdd() {
      this.$store.commit(ADD_FORM_ITEM, { parentId: 0 });
    },
    applyReorder(value) {
      this.$store.commit(SET_SECTIONS, value);
    },
    applySubsectionReorder(id, value) {
      let payload = { id: id, sections: value};
      this.$store.commit(SET_SUBSECTION, payload);
    }
  }
};
</script>

<style>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.w-150 {
  width: 150px;
}
</style>
