<template>
  <div>
    <div ref="holder" @paste="onPaste"></div>
    <b-form-checkbox :checked="isAddRowsEnabled" @input="onRespondentsAddRowsEnabled" switch style="display:inline-block">
      Respondents can add and remove rows
    </b-form-checkbox>
  </div>
</template>

<script>
import TableEditor from '@broker/blockFormEditor/ui/editor/contentEditable/table/index.js';
import { mergeCurrentWithPastedTable } from '@broker/blockFormEditor/ui/editor/contentEditable/table/utils/paste.js';

export default {
  props: {
    value: Array,
    active: Boolean,
    isAddRowsEnabled: Boolean
  },
  data() {
    return {
      table: {}
    };
  },
  mounted() {
    if (this.$debug) console.log('Mounted', this.table);
    this.init();
  },
  watch: {
    value: function() {
      this.init();
    },
    active: function(value) {
      if (value === false) {
         this.emitTable();
      }
    }
  },
  methods: {
    init(val) {
      val = val || this.value;
      if (this.$debug) console.log('init', val, this.active);
 
      this.table = new TableEditor(val, null, false, true);
      this.$refs.holder.innerHTML = '';
      this.$refs.holder.appendChild(this.table.render());
    },
    emitTable() {
      let tableData = this.table.save();
      this.$emit('input', tableData);
      
      if (this.$debug) console.log('emitTable', tableData);
    },
    saveTable(){
      return this.table.save();
    },
    onPaste(event){
        let text = (event.originalEvent || event).clipboardData.getData('text/plain');
        if (this.$debug) console.log('paste', text);

        if(text.includes(String.fromCharCode(13)) || String.fromCharCode(9)){
          event.preventDefault();
          let currentData = this.table.save();
          let cellCoord = getCellGridCoordinates(event.target);
          let merge = mergeCurrentWithPastedTable(text, currentData, cellCoord)
          this.$emit('input', merge);
        }
    },
    onRespondentsAddRowsEnabled(value) {
      this.$emit('addRowsToggle', value);
    }
  }
};

function getCellGridCoordinates(cell) {
  let colIdx = 0;
  let rowIdx = 0;

  if (cell.classList.contains('tc-cell')) {
    let row = cell.parentNode;
    let table = row.parentNode;

    colIdx = Array.prototype.indexOf.call(row.children, cell);
    rowIdx = Array.prototype.indexOf.call(table.children, row);
  }

  return { row: rowIdx, col: colIdx };
}
</script>

<style scoped>
.table-options-container {
  display: flex;
  justify-content: space-between;
}
</style>
