<template>
  <div class='container'>
    <div class='lay-options-right'>
      <VEditButton class='eb-sec' @click='onAddForm'>New Template</VEditButton>
    </div>
    <hr />
    <div class='row text-center pt-3'>
      <div class='col-sm'>
        <h3>Form Templates Library</h3>
      </div>
    </div>
    <div class='list-group pt-3'>
      <VFormTemplateListItem v-for='form in forms' :key='form.id' v-bind='form' @delete='onDelete(form.id)'></VFormTemplateListItem>
    </div>
    <v-dialog></v-dialog>
  </div>
</template>

<script>
import VFormTemplateListItem from '@broker/formsLibrary/ui/VFormTemplateListItem.vue';
import deleteTemplateDialog from '@broker/formsLibrary/ui/deleteTemplateDialog.js'

export default {
  components: {
    VFormTemplateListItem,
  },
  data() {
    return {
      forms: []
    };
  },
  created() {
    this.fetchData();
  },
  watch: {
    $route: 'fetchData'
  },
  methods: {
    fetchData() {
      this.$api.template.getAll()
        .then(res => {
          this.forms = res.data;
          this.forms.forEach(x => {
            x.link = `/broker/formTemplate/${x.id}/edit`;
          });
        })
    },
    onAddForm() {
      this.$router.push({ path: '/broker/formTemplate/new' });
    },
    onDelete(id){
      deleteTemplateDialog(this.$modal, this.$api, id, () => {
            this.fetchData();
        });
    }
  }
};
</script>

<style>
</style>
