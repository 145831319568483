<template>
  <div @click="notifyComponentClicked" class="edit-holder row pb-2" :class="isActiveClass">
    <div class="col">
      <div class="row">
        <div class="col-sm-1"></div>
        <div class="col-sm-10 text-center d-flex flex-column justify-content-center handle-holder">
          <i class="fas fa-grip-horizontal handle"></i>
        </div>
        <div class="col-sm-1 text-center d-flex flex-column justify-content-center delete-holder">
          <i class="far fa-times-circle delete-icon cool-danger" @click="onDelete"></i>
        </div>
      </div>
      <div class="row">
        <div class="col" style="display:flex">
          <div style="height:100%">
            <TypeSelector :type="type" :id="id" :active="isActive"></TypeSelector>
            <EditorDisplayIf :id="id" :ifQ="ifQ" v-if="!this.ifQ && !this.editorIfActive" style="width:100px" @showon="onEditorIfActive"></EditorDisplayIf>
          </div>
          <div class="ml-2" style="width:100%;">
            <VContentEditablePara
              v-if="isOptionsType"
              :active="isActive"
              v-model="questionText"
              @newEntry="onNewEntry"
            ></VContentEditablePara>
            <VContentEditableText v-else :active="isActive" v-model="questionText" @newEntry="onNewEntry"></VContentEditableText>
            <VTableEditable
              ref="tableEditableRef" 
              v-if="type === 'qtable'"
              :active="isActive"
              :isAddRowsEnabled="true"
              v-model="tableEditorData"
              @addRowsToggle="onAddRowsToggle"
            >
            </VTableEditable>
          </div>
        </div>
      </div>
      <div v-if="this.ifQ || this.editorIfActive" class="row">
        <div class="col">         
          <EditorDisplayIf :id="id" :ifQ="ifQ" style="width:100%"></EditorDisplayIf>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-11"></div>
        <div class="col-sm-1 text-center d-flex flex-column justify-content-center add-holder">
          <i class="ml-6 fas fa-plus-circle cool-green add-icon" @click="onNewEntry"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VContentEditableText from '@broker/blockFormEditor/ui/editor/contentEditable/VContentEditableText.vue';
import VContentEditablePara from '@broker/blockFormEditor/ui/editor/contentEditable/VContentEditablePara.vue';
import VTableEditable from '@broker/blockFormEditor/ui/editor/contentEditable/VTableEditable.vue';
import TypeSelector from '@broker/blockFormEditor/ui/editor/typeSelector/TypeSelector.vue';
import EditorDisplayIf from '@broker/blockFormEditor/ui/editor/qifEditor/EditorDisplayIf.vue';

import proposalEditHelper from '@broker/blockFormEditor/utils/proposalEditHelper.js';
import scrollMeTo from '@shared/components/stepper/utils/scrollMeTo.js';

import { mapToEditorData } from '@broker/store/tableEditorFns.js';

const SET_FORM_ITEM = 'editor/setFormItem';
const IS_ACTIVE = 'editor/isActive';

export default {
  components: { VContentEditableText, VContentEditablePara, TypeSelector, VTableEditable, EditorDisplayIf },
  name: 'EditorBlock',
  props: {
    type: String,
    id: Number,
    question: String,
    text: String,
    options: Array,
    columns: Array,
    isAddRowsEnabled: Boolean,
    tableData: Array,
    ifQ: Object,
    tableEditData: Array
  },
  data() {
    return {
      editorIfActive: false
    }
  },
  computed: {
    questionText: {
      get() {
        if (proposalEditHelper.isNotQuestion(this.type)) {
          return this.text;
        } else if (this.isOptionsType) {
          var asArr = [this.question, ...this.options];
          return asArr.join('\n- ');
        }
        return this.question;
      },
      set(value) {
          this.update(value);
      }
    },
    tableEditorData: {
      get() {
        if (this.tableEditData) {
          return this.tableEditData;
        } else {
          // convert to array of arrays
          return mapToEditorData(this.columns, this.tableData);
        }
      },
      set(value) {
        this.updateGrid(value);
      }
    },
    isOptionsType() {
      return proposalEditHelper.questionWithOptions(this.type) === true;
    },
    isActiveClass() {
      return this.$store.getters[IS_ACTIVE](this.id) ? 'component-active' : 'component-inactive';
    },
    isActive() {
      return this.$store.getters[IS_ACTIVE](this.id);
    }
  },
  methods: {
    notifyComponentClicked() {
      this.$store.commit('editor/setFormActive', this.id);
      scrollMeTo.on(this.type + this.id);
    },
    currentComponent() {
      return proposalEditHelper.component(this.id, this.type, this.$store);
    },
    onNewEntry(val) {
      if(this.$debug) console.log("onNewEntry", val)
      this.$store.dispatch('editor/UPDATE', {commit: 'addAfterItem', value: this.id});
    },
    update(value) {
      let payload = { id: this.id, data: { question: value } };
      if (proposalEditHelper.isNotQuestion(this.type)) {
        payload = { id: this.id, data: { text: value } };
      } else if (this.isOptionsType) {
        let question = value.split('\n')[0];
        let options = convertOptionsToArry(value);
        payload.data = { question: question, options: options };
      } else if (this.type === 'qtable'){
        let res = this.$refs.tableEditableRef.saveTable();
        if (this.$debug) console.log('tableSaveData', res);
        payload.data.tableEditData = res;
      }
      this.$store.dispatch('editor/UPDATE', {commit: 'setFormItem', value: payload});
    },
    onDelete() {
      this.$store.dispatch('editor/UPDATE', {commit: 'deleteFormItem', value: this.id});
    },
    onAddRowsToggle(value) {
      let payload = { id: this.id, data: { isAddRowsEnabled: value } };
      this.$store.commit(SET_FORM_ITEM, payload);
    },
    updateGrid(value) {
      let payload = { id: this.id, data: { tableEditData: value } };
      this.$store.dispatch('editor/UPDATE', {commit: 'setFormItem', value: payload});
    },
    onEditorIfActive(value){
      this.editorIfActive = value;
    }
  }
};

function convertOptionsToArry(value) {
  let options = value.match(/^-(.*)/gm);

  if (options != null) {
    options = options.map(element => {
      return element.replace(/^-\s*/gm, '');
    });
  }
  return options;
}
</script>

<style scoped>
.on-question {
  font-size: 0.8rem;
}

.on-question-it {
  font-style: italic;
}
.handle {
  display: none;
}
.edit-holder:hover .handle {
  display: block;
}

.handle-holder {
  height: 32px;
  cursor: move;
}

.handle-holder:hover {
  display: block;
}

.delete-holder {
  height: 32px;
}
.delete-icon {
  display: none;
}
.edit-holder:hover .delete-icon {
  display: block;
  cursor: pointer;
}
.component-active {
  background-color: #f1f1f1;
}

.component-inactive:hover .component-holder {
  background-color: #f1f1f1;
}
.component-inactive:hover {
  background-color: #f1f1f1;
}

.add-holder {
  height: 32px;
}

.add-icon {
  display: none;
}

.edit-holder:hover .add-icon {
  display: block;
  cursor: pointer;
}

</style>
