<template>
  <fieldset>
    <legend tabindex='-1' class='bv-no-focus-ring question-label col-form-label pt-0 '>{{question}}</legend>
    <div :id='elementId' tabindex='-1'>
      <div v-for='(option, index) in options' :key='index' class='custom-control custom-checkbox bv-no-focus-ring' tabindex='-1'>
        <input :id='optionId(index)' type='checkbox' :value='option' :checked='selected' class='custom-control-input'
          :name='elementId' autocomplete='off' />
        <label :for='optionId(index)' class='custom-control-label'>
          <span>{{option}}</span>
        </label>
      </div>
    </div>
  </fieldset>
</template>

<script>
export default {
  props: {
    id: Number,
    question: String,
    if: Object,
    options: Array,
    type: String
  },
  computed: {
    elementId() {
      return this.type + this.id;
    },
    // ToDo: Get this from prop
    selected() {
      let it =  this.$store.getters['editor/answer'](this.id);
      return it || [];
    },
  },
  methods: {
    optionId(idx) {
      return this.elementId + '_' + idx;
    }
  }
};
</script>

<style scoped>
</style>