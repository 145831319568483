import axios from '@shared/services/api/axiosConfig.js';

export default {
  // no auth
  async featureDemo(){
    const res = await axios.get('/api/utils/feature/demo')
    return res;
  },

  async featureBrokerageSettings(){
    const res = await axios.get('/api/utils/feature/brokeragesettings')
    console.log(res);
    return res;
  }
}
