<template>
  <div>
    <label tabindex='-1' class='question-label col-form-label pt-0 bv-no-focus-ring' :for="elementId">{{question}}</label>
    <multiselect :id='elementId' :value="selected" :options="options" :searchable="false" 
    :show-labels="false" placeholder="Pick a value">
    </multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect,
  },
  props: {
    id: Number,
    question: String,
    options: Array,
    type: String,
  },
  computed: {
    elementId() {
      return this.type + this.id;
    },
    selected() {
      return this.$store.getters['editor/answer'](this.id);
    },
  },
  methods: {
    optionId(idx) {
      return this.elementId + '_' + idx;
    }
  }
};
</script>


<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
</style>