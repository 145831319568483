<template>
  <BlockEditorPageRoot @back="onBack" @save="onSave"></BlockEditorPageRoot>
</template>

<script>
import BlockEditorPageRoot from '@broker/blockFormEditor/pages/BlockEditorPageRoot.vue';

export default {
  components: {
    BlockEditorPageRoot,
  },
  props: ['formId', 'isNew'],
  created() {
    this.fetchData();
  },
  watch: {
    $route: 'fetchData',
  },
  mounted() {
    document.addEventListener('keydown', this.onUndo);
  },
  destroyed() {
    document.removeEventListener('keydown', this.onUndo);
  },
  methods: {
    fetchData() {
      if (this.isNew) {
        this.$store.dispatch('editor/INIT_NEW_TEMPLATE');
      } else {
        this.$store.dispatch('editor/INIT_EDIT_TEMPLATE', this.formId);
      }
    },
    async onSave() {
      if (this.$debug) console.log('onsave');
      await this.$store.dispatch('editor/SAVE');

      if (this.isNew){
        this.$router.push(`/broker/formTemplate/${this.$store.state.editor.dbId}/edit`);
      }
      this.$toast('Saved');
    },
    onBack() {
      this.$router.push('/broker/formTemplates');
    },
    onUndo(event) {
      if (event.ctrlKey && event.keyCode === 90) {
        if (this.$debug) console.log('CTRL+Z has been pressed');
        this.$store.dispatch('editor/UNDO');
      }
    },
  },
};

</script>

<style scoped></style>
