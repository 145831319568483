import axios from '@shared/services/api/axiosConfig.js';
import { getAuthHeaders } from '@shared/services/auth/authApiServices.js';
import { apiPathStart, downloadToComputer } from '@broker/services/api/brokerApiHelper.js'

const debug = process.env.NODE_ENV !== 'production';

export default {
  // Unique to broker
  async postChangeState(id, newState) {
    const headers = await getAuthHeaders();
    const res = await axios.post(`/api/proposal/${id}/state`, { data: newState}, headers)
    if (debug) console.log('postChangeState', res);
    return res;
  },
  async postSaveProposalEdit(id, payload){
    const headers = await getAuthHeaders();
    const res = await axios.post(`/api/proposal/${id}/form`, payload, headers)
    return res;
  },
  async postRenewal(proposalId, templateId){
    const headers = await getAuthHeaders();
    const res = await axios.post(`/api/proposal/${proposalId}/renew/${templateId}`, null, headers)
    return res;
  },
  async postRename(id, name){
    const headers = await getAuthHeaders();
    const res = await axios.post(`/api/proposal/${id}/meta`, {name: name}, headers)
    return res;
  },
  async download(id, proposalName, clientName) {
    const headers = await getAuthHeaders();
    headers.responseType = 'arraybuffer'

    const res = await axios.get(`/api/proposal/${id}/download`, headers);
    let filename = `${clientName} - ${proposalName}`
    filename = filename.replace(/[^a-z0-9]/gi, '_')

    downloadToComputer(res.data, 'application/zip', filename + '.zip');

    return res;
  },
  async deleteProposal(id) {
    const headers = await getAuthHeaders();
    const res = await axios.delete('/api/proposal/'+id, headers)
    if (debug) console.log('deleteProposal', res);
    return res;
  },
  async getProposalQuestions(id) {
    const headers = await getAuthHeaders();
    const res = await axios.get(`/api/proposal/${id}/questions`, headers)
    return res;
  },
  async postImportAnswers(toProposalId, fromProposalId) {
    const headers = await getAuthHeaders();
    const res = await axios.post(`/api/proposal/${toProposalId}/prefillCopy/${fromProposalId}`, null, headers)
    return res;
  },

  // Common to both controllers
  async getProposalFill(id){
    const headers = await getAuthHeaders();
    const res = await axios.get(`/api/proposal/${id}`, headers)
    if (debug) console.log('getProposalFill', res);
    return res;
  },
  async postAnswer(id, payload){
    const headers = await getAuthHeaders();
    headers.errorHandle = false; // disable global error handler
    const res = await axios.post(`/api/proposal/${id}/answer`, payload, headers)
    return res;
  },
  async postRetryAnswers(id, payload){
    const headers = await getAuthHeaders();
    headers.errorHandle = false; // disable global error handler
    const res = await axios.post(`/api/proposal/${id}/retryAnswers`, payload, headers)
    return res;
  },
  async deleteSupportingDoc(proposalId, supportingDocId){
    const headers = await getAuthHeaders();
    const res = await axios.delete(`/api/proposal/${proposalId}/deleteDoc/${supportingDocId}`, headers)
    return res;
  },
  urlSupportingDocUpload(id, questionId) {
    let initPath = `/api/proposal/${id}/SupportingDoc/${questionId}`;
    let fullPath = apiPathStart() + initPath
    return fullPath;
  },
  urlSupportingDocDownload(id, supportingDocId) {
    let initPath = `/api/proposal/${id}/downloadDoc/${supportingDocId}`;
    let fullPath = apiPathStart() + initPath
    return fullPath;
  },
}
