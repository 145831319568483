<template>
  <div class="list-group-item list-group-item-action flex-column">
    <div class="media pt-2 pb-2">
      <img v-if="hasLandingImage" class="bd-placeholder-img mr-2 rounded" :src="landingImageSrc" alt="Landing Image"  style="height: 85px; width: 150px"/>
      <svg v-else class='bd-placeholder-img mr-2 rounded' width='150' height='85' xmlns='http://www.w3.org/2000/svg' focusable='false' role='img' aria-label='Placeholder'>
        <title>Placeholder</title>
        <rect width="100%" height="100%" :fill="fillColour" />
      </svg>
      <div class="media-body mb-0 ml-2" style="height: 100%">
        <div class="d-flex justify-content-between">
          <h5 v-if="state === 'Lock'">{{ name }}</h5>
          <VH5Edit
            v-else
            :text="name"
            :allowEmpty="false"
            @text-updated="onProposalNameUpdated"
            :formBadgeStatus="formBadgeStatus"
            :stateMessage="stateMessage"
          ></VH5Edit>

          <div class="d-flex justify-content-between">
            <!-- <div class='icon-holder'>
              <i title="New comments" class='far fa-comment-alt fa-lg'></i>
              <span v-if='comments > 1' class='badge badge-pill badge-danger badge-notify'>{{comments}}</span>
            </div> -->
            <div v-if="state === 'Draft'">
              <i class="fas fa-trash fa-lg action-icon-danger cool-danger" @click="onDelete" title="Delete Proposal"></i>
            </div>
          </div>
        </div>
        <p class="mb-0 text-muted">
          <small>Title: {{ displayTitle }}</small>
        </p>
        <!-- Links -->
        <div style="display:flex; justify-content: space-between; align-items:flex-end">
          <VProposalListItemLinks v-bind="$props" 
            @renewal="$emit('renewal', id)" 
            @download="$emit('download', id)"
            @importAnswers="$emit('importAnswers', id)"
            >
          </VProposalListItemLinks>

          <!-- Buttons -->
          <div v-if="state === 'Draft'">
            <VEditButton class="eb-success width-120" @click="onActivate">Publish</VEditButton>
          </div>
          <div v-if="isActiveOrCompleted">
            <VEditButton class="eb-sec" @click="onDraft" title="Hides Proposal from user and allows editing or deleting"
              >Unpublish</VEditButton
            >
            <VEditButton
              class="eb-success width-120"
              @click="onLock"
              title="Lock Proposal - Disables client link and form can no longer be edited"
              >Lock</VEditButton
            >
          </div>
          <div v-if="state === 'Lock'">
            <VEditButton
              class="eb-sec width-120"
              @click="onUnlock"
              title="Unlocks the Proposal - The proposal will now be published and editable by the client"
              >Unlock</VEditButton
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VProposalListItemLinks from '@broker/clientProposals/ui/VProposalListItemLinks.vue';

export default {
  components: {
    VProposalListItemLinks
  },
  props: {
    id: Number,
    name: String,
    comments: Number,
    description: String,
    state: String,
    uniqueLink: String,
    pdfGenerating: Boolean,
    displayTitle: String,
    fullLink: String,
    clientLink: String,
    hasDownloads: Boolean,
    landingImage: String,
  },
  computed: {
    isActiveOrCompleted() {
      return this.state === 'Active' || this.state === 'Completed';
    },
    isCompletedOrLocked() {
      return this.state === 'Completed' || this.state === 'Lock';
    },
    formBadgeStatus() {
      switch (this.state) {
        case 'Active':
          return 'badge-primary';
        case 'Completed':
          return 'badge-success';
        default:
          return 'badge-light';
      }
    },
    stateMessage() {
      switch (this.state) {
        case 'Active':
          return 'ACTIVE - Can be filled in by the client. Client visible';
        case 'Completed':
          return 'COMPLETED - Submitted by client. Client visible';
        default:
          return 'DRAFT - Can be edited. NOT visible to the client';
      }
    },
    viewLink() {
      return `/broker/proposal/${this.id}/fill`;
    },
    renameFormLink() {
      return `/broker/proposal/${this.id}/editMeta`;
    },
    editFormLink() {
      return `/broker/proposal/${this.id}/edit`;
    },
    fillColour() {
      const coolours = ['#41337a', '#6ea4bf', '#fab2ea', '#f07167', '#3F4B3B', '#2b2d42', '#5e548e', '#ef233c', '#e6beae', '#e7d8c9', '#577399', '#1a936f']

      return coolours[this.id % coolours.length];
    },
    hasLandingImage(){
      return this.landingImage != null;
    },
    landingImageSrc() {
      return this.$api.apiPathStart() + `/api/proposal/landingimage/${this.landingImage}`;
    }
  },
  methods: {
    onDelete() {
      this.$emit('delete', this.id);
    },
    onActivate() {
      this.$emit('activate', this.id);
    },
    onDraft() {
      this.$emit('draft', this.id);
    },
    onLock() {
      this.$emit('lock', this.id);
    },
    onUnlock() {
      this.$emit('unlock', this.id);
    },
    onProposalNameUpdated(name) {
      this.$emit('rename', { id: this.id, name: name });
    }
  }
};
</script>

<style scoped>
.badge-notify {
  position: relative;
  top: -10px;
  left: -10px;
}

.icon-holder {
  width: 50px;
  margin-right: -12px;
}

.title-badge {
  font-size: 0.5em;
  vertical-align: top;
  margin-left: 0.5em;
}

.width-120 {
  width: 120px;
}
</style>
