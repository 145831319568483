import axios from '@shared/services/api/axiosConfig.js';
import { getAuthHeaders } from '@shared/services/auth/authApiServices.js';
const debug = process.env.NODE_ENV !== 'production';


export default {
  async getAllCompanies() {
    const headers = await getAuthHeaders();
    const res = await axios.get('/api/company/all', headers);

    if (debug) console.log('getAllCompanies', res);
    
    return res;
  },
  async getProposalsForClient(clientId){
    const headers = await getAuthHeaders();
    const res = await axios.get(`/api/company/${clientId}/proposals`, headers)

    if (debug) console.log('getProposalsForClient', res);

    return res;
  },
  async postNewCompany(client){
    if (debug) console.log('postNewCompany');
    const headers = await getAuthHeaders();    
    const res = await axios.post('/api/company/new', client, headers)
    return res;
  },
  async postEditCompany(clientId, name){
    if (debug) console.log('postEditCompany');
    const headers = await getAuthHeaders();
    const res = await axios.post(`/api/company/${clientId}/${name}`, null, headers)
    return res;
  },
  async postAddFormToClient(clientId, formId){
    const headers = await getAuthHeaders();
    const res = await axios.post(`/api/company/${clientId}/addform/${formId}`, null, headers)
    if (debug) console.log('postNewCompany', res);
    return res;
  },
  async deleteCompany(clientId){
    const headers = await getAuthHeaders();
    const res = await axios.delete(`/api/company/${clientId}`, headers);
    return res;
  },
}
