import { getAuthInstance } from '@shared/services/auth/auth.js';


export async function getAuthHeaders() {
  const accessToken = await getAuthInstance().getTokenSilently();
  return {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
}
