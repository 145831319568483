
<!-- https://www.digitalocean.com/community/tutorials/vuejs-vue-form-handling -->
<template>
     <div v-if="featureToggle.data == false" class="container">
      <Page404 :errorText="featureDisabled" :errorCode="errorCode"></Page404>
    </div>
  <div v-else class="container">
    <div class="row">
      <div class="col-sm">
        <div class="lay-card mt-5 mb-5">
          <form class="mt-3"  @submit.prevent="saveChanges">
            <div class="lay-card-top">
              <h5 class="card-title">Brokerage Details</h5>

              <div class="form-group">
                <label for="brokeragename">Name:</label>
                  <input
                    type="text"
                    id="brokeragename"
                    placeholder="Name here"
                    v-model="brokeragename"
                    style="width:100%"
                    class="form-control"
                  />
              </div>

              <div class="form-group">
                <label for="brokerageemail">Email:</label>
                  <input
                    type="text"
                    id="brokerageemail"
                    placeholder="Email here"
                    required
                    v-model="brokerageemail"
                    class="form-control"
                    style="width:100%"
                    @blur="validateEmail"
                  />
              </div>

              <div class="form-group">
                <label for= "brokerageinfo">Important Information:</label>
                  <textarea
                    type="text"
                    id="brokerageinfo"
                    placeholder="Important information here"
                    v-model="brokerageinfo"
                    style="width:100%"
                    class="form-control"
                  />
              </div>

              <div class="form-group">
                <label for='brokeragedisclaimer'>Disclaimer:</label>
                  <textarea
                    type="text"
                    id="brokeragedisclaimer"
                    placeholder="Disclaimer here"
                    v-model="brokeragedisclaimer"
                    style="width:100%"
                    class="form-control"
                  />
              </div>

              <div class="form-group">
                <label for= 'brokeragetenant'>Tenant:</label>
                  <input
                    type="text"
                    id="brokeragetenant"
                    placeholder="Tenant name here"
                    v-model="brokeragetenant"
                    style="width:100%"
                    class="form-control"
                  />
              </div>

              <div class="row align-items-center">
                <div class="col">
                  <label
                    >Left Side Logo on PDF:
                    <vue-dropzone
                      v-model="leftSideLogo"
                      ref="leftSideDropzone"
                      id="leftlogodropzone"
                      :options="dropzoneOptions"
                      @vdropzone-complete="onLeftSideUploadComplete"
                    ></vue-dropzone>
                  </label>
                </div>

                <div class="col">
                  <img src="/assets/image/Brokerage_Page_Image.png" width="400" />
                </div>

                <div class="col">
                  <label
                    >Right Side Logo on PDF:
                    <vue-dropzone
                      v-model="rightSideLogo"
                      ref="rightSideLogoDropzone"
                      id="rightlogodropzone"
                      :options="dropzoneOptions"
                      @vdropzone-complete="onRightSideUploadComplete"
                    ></vue-dropzone>
                  </label>
                </div>
              </div>

              <div class="form_field">
                <div class="form_label">
                  <strong>Please choose a primary color:</strong>
                </div>
                <div class="form_input">
                  <v-swatches v-model="primarycolor" show-fallback popever-x="left"></v-swatches>
                </div>
              </div>

              <div class="form_field">
                <div class="form_label">
                  <strong>Please choose a secondary color:</strong>
                </div>
                <div class="form_input">
                  <v-swatches v-model="secondarycolor" show-fallback popever-x="left"></v-swatches>
                </div>
              </div>

              <div class="lay-card-bottom">    
                <VEditButton class="eb-success" type="submit">Save Changes</VEditButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VSwatches from 'vue-swatches';
import 'vue-swatches/dist/vue-swatches.css';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { getAuthHeaders } from '@shared/services/auth/authApiServices.js';

export default {
  components: { VSwatches, vueDropzone: vue2Dropzone },

  data() {
    return {
      brokeragename: '',
      brokerageid: 0,
      brokerageemail: '',
      brokerageinfo: '',
      brokeragedisclaimer: '',
      brokeragetenant: '',
      leftSideLogo: '',
      rightSideLogo: '',
      primarycolor: '#1CA085',
      secondarycolor: '#E84B3C',
      imageURL: '',
      featureDisabled: 'Feature Disabled',
      errorCode: '404', 
      featureToggle: false,
    };
  },
  async created() {
    this.featureToggle = await this.$api.utils.featureBrokerageSettings();
    if (this.$debug) 
    {
      console.log(this.featureToggle.data);
    }
    const headers = await getAuthHeaders();
    this.dropzoneOptions.headers.Authorization = headers.headers.Authorization;
    
    // TODO: Load the existing brokerage details
  },
  computed: { 
    uploadUrl() {
      let fullPath = this.$api.brokerage.brokerageLogoUpload() + `/postbrokeragelogo`;
      return fullPath;
    },

    dropzoneOptions() {
      return {
        url: this.uploadUrl,
        thumbnailWidth: 150,
        maxFilesize: 10,
        maxFiles: 1,
        dictDefaultMessage: "<i class='fa fa-upload'></i> Drop your logo here or click to upload a logo",
        headers: {
          'Access-Control-Allow-Origin': 'Access-Control-Allow-Methods: GET, POST'
        }
      };
    }
  },
  methods: {
    validateEmail() {
      if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.emailAddress)) {
        return true;
      } else {
        if (this.$debug) console.log('Please enter a valid email.');
      }
    },

    saveChanges() {
      const payload = {
        name: this.brokeragename,
        emailAddress: this.brokerageemail,
        importantInformation: this.brokerageinfo,
        logo: this.imageURL,
        disclaimerFooter: this.brokeragedisclaimer,
        tenant: this.brokeragetenant,
        primaryColour: this.primarycolor.toString(),
        secondaryColour: this.secondarycolor.toString(),
        leftSideLogo: this.leftSideLogo,
        rightSideLogo: this.rightSideLogo
      };
      this.$api.brokerage.editBrokerage(payload);
    },

    onLeftSideUploadComplete(response) {
      this.leftSideLogo = response.xhr.responseText.replace(/"/g, '');
    },

    onRightSideUploadComplete(response) {
      this.rightSideLogo = response.xhr.responseText.replace(/"/g, '');
    }
  }
};
</script>
<style></style>
