<template>
  <div class="container-fluid">
    <div class="row" style="margin-top: 1rem !important">
      <div class="col-xl-6 col-lg-4 col-md-3">
        <VEditButton class="eb-fade" @click="onToLibrary">Back To Library</VEditButton>
        <VEditButton class="eb-sec" @click="showUpload">{{ importLabel }}</VEditButton>
        <VEditButton class="eb-prim" @click="onAdd">Add</VEditButton>
      </div>
      <div class="col-xl-4 col-lg-6 col-md-7">
        <label class="mr-3">Save As:</label>
        <input type="text" v-model.lazy="saveAsName" class="col-md-10 form-name-edit form-control" />
      </div>
      <div class="col-xl-2 col-lg-2 col-md-1">
        <VEditButton class="eb-success" :disabled="this.saving" @click="onSave">{{ saveLabel }}</VEditButton>
        <VEditButton v-if="!isProd" class="eb-prim" @click="showDebug">JSON</VEditButton>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="full-page editor col-md">
        <div class="question-editor">
          <h1 class="mt-2">This editor is a work in progress</h1>
          <hr />
          <div v-if="isUpload" >
            <div class="form-inline"> 
              <div class="form-group">
                <label>Skip Pages. Ex: 1,2,10,12,13
                  <input type="text" class="form-control ml-3" v-model="skipPages">
                </label>
              </div>
            </div>
            <ImportUploader @upload="onFormUploaded" :skipPages="skipPages"></ImportUploader>
          </div>

          <div v-else >
            <JsonEditor v-if="isDebug"  :form="form" :nextElementId="nextId"></JsonEditor>
            <FormEditor v-else v-bind="form"></FormEditor>
          </div>
        </div>
      </div>
      <div class="full-page preview d-sm-none d-md-block col-md">
        <h1 class="mt-2">This editor is a work in progress</h1>
        <hr />
        <h1 class="mt-2">{{ displayTitle }}</h1>
        <VFormDisplay v-bind="form"> </VFormDisplay>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import VFormDisplay from '@broker/blockFormEditor/ui/render/VFormDisplay.vue';
import FormEditor from '@broker/blockFormEditor/ui/editor/FormEditor.vue';
import ImportUploader from '@broker/blockFormEditor/ui/ImportUploader.vue';
import JsonEditor from '@broker/blockFormEditor/ui/editor/raw/JsonEditor.vue';

export default {
  components: {
    VFormDisplay,
    FormEditor,
    ImportUploader,
    JsonEditor,
  },
  data() {
    return {
      isDebug: false,
      isUpload: false,
      skipPages: ""
    };
  },
  computed: {
    ...mapState('editor', ['form', 'formNotExistError', 'displayTitle', 'formName', 'nextId', 'saving']),
    ...mapGetters(['isProd']),
    saveAsName: {
      get() {
        return this.formName;
      },
      set(value) {
        if (this.$debug) console.log('onUpdatedFormName', value);
        this.newTemplate(value);
        this.$store.commit('editor/updateFormName', value);
      },
    },
    importLabel() {
      return this.isUpload ? 'Cancel' : 'Import';
    },
    saveLabel () {
      return this.saving ? 'Saving' : ' Save ';
    }
  },
  methods: {
    showDebug() {
      this.isDebug = !this.isDebug;
    },
    showUpload() {
      this.isUpload = !this.isUpload;
    },
    onToLibrary() {
      this.$emit('back');
    },
    onSave() {
      this.$emit('save');
    },
    onAdd() {
      this.$store.dispatch('editor/UPDATE', {commit: 'addFormItem', value: {parentId : 0}});
    },
    onFormUploaded(data) {
      this.$store.dispatch('editor/INIT', data);
      this.isUpload = false;
    },
    newTemplate(value) {
      if (value !== this.formName){
        this.$store.commit('editor/newTemplate');
      }
    }
  },
};
</script>

<style scoped>
.full-page {
  height: calc(100vh - 200px);
  overflow: scroll;
}

.form-name-edit {
  display: inline;
}

</style>
