import axios from '@shared/services/api/axiosConfig.js';
import { getAuthHeaders } from '@shared/services/auth/authApiServices.js';
import { apiPathStart } from '@broker/services/api/brokerApiHelper.js'
const debug = process.env.NODE_ENV !== 'production';


export default {
  async postBrokerage(brokerage){
    if (debug) console.log('postBrokerage');
    const headers = await getAuthHeaders();    
    const res = await axios.post(`/api/brokerage/postbrokerage`, brokerage, headers)
    return res;
  },

  async editBrokerage(brokerage){
    if (debug) console.log('editBrokerage');
    const headers = await getAuthHeaders();    
    const res = await axios.post(`/api/brokerage/editbrokerage`, brokerage, headers)
    return res;
  },

  brokerageLogoUpload()
  {
    let path = `/api/brokerage/`;
    let fullPath = apiPathStart() +path
    return fullPath;
  }
}
