import { render, staticRenderFns } from "./VQUpload.vue?vue&type=template&id=5bca7628&scoped=true&"
import script from "./VQUpload.vue?vue&type=script&lang=js&"
export * from "./VQUpload.vue?vue&type=script&lang=js&"
import style0 from "./VQUpload.vue?vue&type=style&index=0&id=5bca7628&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bca7628",
  null
  
)

export default component.exports