<template>
  <div class="lay-nav-wrapper">
    <QuestionsStepperSidebar
      :bookmarks="tableOfContents"
      :focusedIds="focus"
      :autosaving="saving"
      :offline="offline"
    ></QuestionsStepperSidebar>
    <div v-if="formNotExistError || isLoading" class="container">
      <PageLoading loadingText="Loading Form. Please wait..." v-if="isLoading"></PageLoading>
      <Page404 v-else></Page404>
    </div>
    <div v-else class="container">
      <div class="lay-options-both">
        <VEditButton class="eb-fade" @click="onBack">Back To Client</VEditButton>
      </div>
      <hr />
      <h1 class="mt-2">{{ meta.displayTitle }}</h1>
      <div v-for="(content, index) in filteredSections" :key="content.id">
        <QuestionSection v-bind="content" :index="index" :totalSections="filteredSections.length"></QuestionSection>
        <hr />
      </div>
      <div class="mb-2">
        <VEditButton class="eb-fade" @click="onBack">Back To Client</VEditButton>
      </div>
    </div>
    <VFormOffline />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import QuestionSection from '@shared/components/questions/ui/QuestionSection.vue';
import QuestionsStepperSidebar from '@shared/components/stepper/QuestionsStepperSidebar.vue';
import VFormOffline from '@shared/components/formFilling/VFormOffline.vue';

export default {
  components: {
    QuestionSection,
    QuestionsStepperSidebar,
    VFormOffline
  },
  props: ['proposalId'],
  computed: {
    ...mapState('fill', [
      'form',
      'meta',
      'focus',
      'tableOfContents',
      'formNotExistError',
      'isLoading',
      'saving',
      'offline'
    ]),
    ...mapGetters('fill', ['filteredSections'])
  },
  created() {
    this.fetchData();
  },
  watch: {
    $route: 'fetchData'
  },
  methods: {
    onBack() {
      if (this.meta && this.meta.companyId) {
        let clientId = this.meta.companyId;
        this.$router.push(`/broker/client/${clientId}/proposals`);
      }
    },
    async fetchData() {
      var identifier = this.proposalId;
      const propPromise = this.$store.dispatch('fill/INIT', { proposalIdentifier: identifier, controllerLink: 'internal', editable: true });
      const commentsPromise = this.$store.dispatch('comments/INIT', { proposalIdentifier: identifier, user: 'Broker', controllerLink: 'internal' });
      const prefillPromise = this.$store.dispatch('preFill/INIT', { proposalIdentifier: identifier });
          
      await Promise.all([propPromise, commentsPromise, prefillPromise]);
    }
  }
};
</script>

<style scoped></style>
