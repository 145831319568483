/* eslint-disable */
import Table from './table';
import * as $ from './utils/dom';

/**
 * @typedef {object} TableConfig - configuration that the user can set for the table
 * @property {number} rows - number of rows in the table
 * @property {number} cols - number of columns in the table
 */
/**
 * @typedef {string[][]} TableData -two-dimensional array which contains table content
 */

/**
 * Table block Heavily borrowed from Editor.js https://github.com/editor-js/table
 */
export default class TableBlock {

  /**
   * Render plugin`s main Element and fill it with saved data
   *
   * @param {TableData} tableData — previously saved data
   * @param {TableConfig} config - user config for Tool
   * @param {boolean} readOnly - read-only mode flag
   * @param {boolean} withHeadings - display table with headers
   */
  constructor(tableData, config, readOnly, withHeadings) {
    this.readOnly = readOnly;
    this.data = {
      withHeadings: withHeadings ? withHeadings : false,
      content: tableData ? tableData : []
    };
    this.config = config;
    this.table = null;
  }

  /**
   * Return Tool's view
   *
   * @returns {HTMLDivElement}
   */
  render() {
    /** creating table */
    this.table = new Table(this.readOnly, this.data, this.config);

    /** creating container around table */
    this.container = $.make('div', 'block');
    this.container.appendChild(this.table.getWrapper());

    this.table.setHeadingsSetting(this.data.withHeadings);

    return this.container;
  }

  /**
   * Extract table data from the view
   *
   * @returns {TableData} - saved data
   */
  save() {
    const tableContent = this.table.getData();
    return tableContent;
  }

  /**
   * Plugin destroyer
   *
   * @returns {void}
   */
  destroy() {
    this.table.destroy();
  }
}
