<template>
  <div class='mt-5 mb-5 pg-break-print'>
    <div v-for='content in sections' :key='content.id' class="form-group">
      <div>
        <EditorViewItemHolder :proposalItem="content" :allowSections="true"></EditorViewItemHolder>
      </div>
    </div>
  </div>
</template>

<script>
import editHelper from '@broker/blockFormEditor/utils/proposalEditHelper.js';
import EditorViewItemHolder from '@broker/blockFormEditor/ui/render/EditorViewItemHolder.vue';

export default {
  components: {
    EditorViewItemHolder
  },
  name: "VFormDisplay",
  props: {
    sections: Array,
  },
  methods: {
    getComponentFromInputType (content) {
      return editHelper.component(content.type);
    }
  },
}
</script>

<style scoped>

</style>
