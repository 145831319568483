import {enrichTableQuestion} from '@broker/store/tableEditorFns.js';
import proposalEditHelper from '@broker/blockFormEditor/utils/proposalEditHelper.js';
import { findRecursively, hierarchyRecursively } from '@shared/store/utils/recursiveFind.js';
import _ from 'lodash'

export function createItem(state) {
  const nextId = state.nextId++;
  const newItem = { id: nextId, type: 'qshort', question: "New Item"};
  return newItem;
}

export function selectableQuestionsRecursively(item, list, id) {
  if (item.id === id){
    list.push({ value: item.id, text: item.question });
    return;
  }

  if (proposalEditHelper.questionWithSelection(item.type)) {
    list.push({ value: item.id, text: item.question });
  } else if (item.sections) {
    item.sections.forEach(elem => {
      selectableQuestionsRecursively(elem, list, id);
    });
  }
}

export function findParent(root, id) {
  let result = hierarchyRecursively(root, id);
  if (result && result.length > 1) {
    return result[result.length - 2];
  } else {
    return null;
  }
}

export function updateRecursive(curItem, searchId, theUpdate) {
  guard(curItem);

  let toReturn = curItem;
  if (curItem.id === searchId) {
    // as immutable create an updated version, We then return that updated version in this recursive call
    toReturn = updated(curItem, theUpdate);
  } else if (curItem.sections) {
    // recursively try and find the object to update and update it.
    // We are generating a new sections array using the map function
    toReturn.sections = curItem.sections.map(elem => {
      return updateRecursive(elem, searchId, theUpdate);
    });
  }

  return toReturn;
}

function guard(curItem) {
  if (curItem.id === undefined || curItem.id === null) {
    console.error('item does not have id');
    throw 'item does not have id ' + JSON.stringify(curItem);
  }
}

function updated(item, theUpdate) {
  let newItem = Object.assign({}, item, theUpdate);

  correctTextQuestion(newItem);

  if (newItem.type === 'qtable') {
    enrichTableQuestion(newItem);
  }

  if (proposalEditHelper.questionWithOptions(newItem.type)) {
    if (newItem.options === undefined || newItem.options === null || newItem.options.length === 0) {
      let options = ['Option 1'];
      newItem.options = options;
    }
  }

  if (newItem.type === 'subsection') {
    newItem.sections = newItem.sections || [];
  }

  return newItem;
}

// ensure questions have question property and others have text property instead
function correctTextQuestion(newItem) {
  if (proposalEditHelper.isNotQuestion(newItem.type)) {
    // non question: text should be set
    if (newItem.question && !newItem.text) {
      newItem.text = newItem.question;
      delete newItem.question;
    }
  } else {
    // question: question should be set
    if (newItem.text && !newItem.question) {
      newItem.question = newItem.text;
      delete newItem.text;
    }
  }
}


export function deleteRecursively(item, id) {
  let indexToDelete = -1;
  for (let index = 0; index < item.sections.length; index++) {
    const element = item.sections[index];
    if (element.id === id) {
      indexToDelete = index;
      break;
    }
    if (element.sections) {
      deleteRecursively(element, id);
    }
  }

  if (indexToDelete > -1) {
    item.sections.splice(indexToDelete, 1);
  }
}

export function reorder(arr, idToFind, moveUp) {
  let curIndex = 0;
  for (let index = 0; index < arr.length; index++) {
    const element = arr[index];
    if (element.id === idToFind) {
      curIndex = index;
    }
  }
  const modifier = moveUp ? -1 : 1;

  arrayMove(arr, curIndex, curIndex + modifier);
}

export function arrayMove(arr, old_index, new_index) {
  if (new_index >= arr.length || new_index < 0) {
    return arr;
  }

  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
}

export function cleanForSave(form){
  let cleaned = cleanForSaveRecursively(form)
  cleaned = fixDuplicateIds(cleaned);
  return cleaned;
}

export function cleanForSaveRecursively(curItem){
  let toReturn = cleanElementForSave(curItem);

  if(curItem.sections) {
    // recursively clean children
    toReturn.sections = curItem.sections.map(elem => {
      if (curItem.type === "subsection" && (elem.type === "subsection" || elem.type === "section")){
        // not allowed to have subsection or section within a subsection
        return null;
      }
      return cleanForSaveRecursively(elem);
    }).filter(x => x !== null);
  }

  return toReturn;
}

function cleanElementForSave(item){
  let clone = Object.assign({}, item);

  delete clone.tableEditData;

  if(clone.type !== "qtable"){
    delete clone.columns;
    delete clone.isAddRowsEnabled;
    delete clone.tableData;
  }
  if(!proposalEditHelper.questionWithOptions(clone.type)){
    delete clone.options;
  }
  if(proposalEditHelper.isNotQuestion(clone.type)){
    delete clone.question;
  }else{
    delete clone.text;
  }

  return clone;
}

/*
* We convert the sections into a flattened structure (preserving subsections) to make editing easier
*/
export function flattenSections(sections){
  let flattened = []
  for (let index = 0; index < sections.length; index++) {
    const element = sections[index];
    flattened.push(element)

    if(element.type === "section" && element.sections){
      element.sections.forEach(child => {
        flattened.push(child)
      });

      delete element.sections;
    }
  }
  
  return flattened;
}

/*
* Sections and questions are on the same level. So we add back in the hierachical structure before we save
*/
export function expandSections(sections, nextId){
  let expanded = []
  let lastSection = null;
  let newNextId = nextId;

  for (let index = 0; index < sections.length; index++) {
    const element = sections[index];

    if(element.type === "section"){
      element.sections = [];
      lastSection = element;
      expanded.push(element);
    }else{
      if(lastSection === null){
        // init a first section
        newNextId = newNextId++;
        lastSection = { id: newNextId, type: 'section', text: 'Introduction', sections: [] };
        expanded.push(lastSection);
      }
      lastSection.sections.push(element);
    }
  }
  
  return [expanded, newNextId]
}

export function findMaxId(form){
  let allIds = findIdsRecursively(form);
  return Math.max( ...allIds );
}

// https://github.com/lodash/lodash/issues/4852#issuecomment-666366511
const uniques = (arr) => _.xor(...arr.map(a => [a]))
const duplicates = (arr) => _.xor(arr, uniques(arr));

export function fixDuplicateIds(form){
  let allIds = findIdsRecursively(form);
  let dups = duplicates(allIds);
  if(dups.length === 0){
    return form;
  }

  let nextId = Math.max( ...allIds ) + 1;

  // this is slow and inefficent but I didn't really want to write something efficent and complex so...
  for (let index = 0; index < dups.length; index++) {
    const id = dups[index];
    let firstInstance = findRecursively(form, id);
    firstInstance.id = nextId++
  }

  return form;
}

function findIdsRecursively(curItem){
  let toReturn = [curItem.id];
  if(curItem.sections) {
    for (let index = 0; index < curItem.sections.length; index++) {
      const element = curItem.sections[index];
      let childrenIds = findIdsRecursively(element);
      toReturn = [...toReturn, ...childrenIds];
    }
  }

  return toReturn;
}
