<template>
  <div v-if="state === 'Draft'">
    <router-link class="cool-alt-link" :to="viewLink">Preview/Fill</router-link>

    <span class="pr-2 pl-2"> | </span>
    <a class="cool-alt-link" @click="onRenewal">Renewal</a>
    <span class="pr-2 pl-2"> | </span>
    <a class="cool-alt-link" @click="importAnswers">Import Answers</a>
<!-- 
    <span class="pr-2 pl-2"> | </span>
    <router-link class="cool-alt-link" :to="editFormLink">Edit Questions</router-link> -->
  </div>
  <div v-else-if="state === 'Active'">
    <router-link class="cool-alt-link" :to="viewLink">Preview/Fill</router-link>

    <span class="pr-2 pl-2"> | </span>
    <a class="cool-alt-link" @click="onRenewal">Renewal</a>
    <span class="pr-2 pl-2"> | </span>
    <a class="cool-alt-link" @click="importAnswers">Import Answers</a>

    <span class="pr-2 pl-2"> | </span>
    <a class="cool-alt-link" @click="copyClientLink">Client Link</a>
  </div>
  <div v-else-if="state === 'Completed'">
    <a v-if="pdfGenerating === false && hasDownloads === true" class="cool-alt-link" @click="onDownload">Download</a>
    <span v-else-if="pdfGenerating === true" title="Please Refresh the page in a minute">Preparing Download</span>
    <span v-else-if="hasDownloads === false" title="The client needs to complete the form">No Completed Forms Found</span>

    <span class="pr-2 pl-2"> | </span>
    <a class="cool-alt-link" @click="onRenewal">Renewal</a>

    <span class="pr-2 pl-2"> | </span>
    <a class="cool-alt-link" @click="copyClientLink">Client Link</a>
  </div>
  <div v-else-if="state === 'Lock'">
    <a v-if="pdfGenerating === false && hasDownloads === true" class="cool-alt-link" @click="onDownload">Download</a>
    <span v-else-if="pdfGenerating === true" title="Please Refresh the page in a minute">Preparing Download</span>
    <span v-else-if="hasDownloads === false" title="The client needs to complete the form">No Completed Forms Found</span>

    <span class="pr-2 pl-2"> | </span>
    <a class="cool-alt-link" @click="onRenewal">Renewal</a>

  </div>
</template>

<script> 
import copyTextToClipboard from '@broker/clientProposals/utils/copyTextToClipboard.js'
import VCopiedToast from '@broker/clientProposals/ui/VCopiedToast.vue'

export default {
  props: {
    id: Number,
    state: String,
    uniqueLink: String,
    fullLink: String,
    clientLink: String,
    pdfGenerating: Boolean,
    hasDownloads: Boolean,
  },
  data() {
    return {
      demoFeatureEnabled: false
    }
  },
  created() {
    this.demoFeatureEnabled = this.$store.getters['features/feature']('demo');
  },
  computed: {
    viewLink(){
      return `/broker/proposal/${this.id}/fill`
    },
    renameFormLink(){
      return `/broker/proposal/${this.id}/editMeta`
    },
    editFormLink(){
      return `/broker/proposal/${this.id}/edit`
    },
  },
  methods: {
    onRenewal(){
      this.$emit('renewal', this.id)
    },
    importAnswers(){
      this.$emit('importAnswers', this.id)
    },
    onDownload(){
      this.$emit('download', this.id)
    },
    copyClientLink(){
      let link = this.$utils.str.isEmpty(this.clientLink) ? this.fullLink : this.clientLink;

      copyTextToClipboard(link)
      const content = {
        component: VCopiedToast,
        props: { link: link }
      };
      const options = {
        timeout: 6000,
        closeOnClick: false,
        draggable: false,
        closeButton: "button",
      };

      this.$toast.info(content, options);
    }
  },
};
</script>

<style scoped>

</style>
