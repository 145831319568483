<template>
  <div class="container">
    <div class="lay-options-both">
      <VEditButton class="eb-fade" @click="onBack">Back</VEditButton>
    </div>
    <hr />
    <VChooseTemplate
      header="Choose a template to use for the renewal"
      :templates="templates"
      @onSelectedTemplate="onSelectedTemplate"
    ></VChooseTemplate>
  </div>
</template>

<script>
import VChooseTemplate from '@broker/clientProposals/ui/VChooseTemplate.vue';

export default {
  components: {
    VChooseTemplate
  },
  props: ['proposalId'],
  data() {
    return {
      templates: [],
    };
  },
  created() {
    this.fetchData();
  },
  watch: {
    $route: 'fetchData'
  },
  methods: {
    fetchData() {
      this.$api.template.getAll().then(res => {
        this.templates = res.data;
      });
    },
    onBack() {
      this.$router.go(-1);
    },
    async onSelectedTemplate(templateId) {
      if (this.$debug) console.log('onSelectedTemplate', templateId);
      this.$router.push({ path: `/broker/proposal/${this.proposalId}/renewal/confirm/${templateId}` });
    }
  }
};
</script>

<style scoped></style>
