import axios from '@shared/services/api/axiosConfig.js';
import { getAuthHeaders } from '@shared/services/auth/authApiServices.js';

export default {
  async getAllIntroducers(){
    const headers = await getAuthHeaders();
    const res = await axios.get(`/api/wholesale/introducer/all`, headers)
    return res;
  },
  async getAllIntroducersForDropdown(){
    const headers = await getAuthHeaders();
    const res = await axios.get(`/api/wholesale/introducer/dropdowns`, headers)
    return res;
  },
  async getWholesaleLink(){
    const headers = await getAuthHeaders();
    const res = await axios.get(`/api/wholesale/link`, headers)
    return res;
  },
  async postNewIntroducer(payload){
    const headers = await getAuthHeaders();
    const res = await axios.post(`/api/wholesale/introducer`, payload, headers)
    return res;
  },
  async postActivateIntroducer(id, newStateIsActive){
    const headers = await getAuthHeaders();
    let url = `/api/wholesale/introducer/${id}/inactivate`
    if (newStateIsActive){
      url = `/api/wholesale/introducer/${id}/activate`
    }

    const res = await axios.post(url, null, headers)
    return res;
  },
}