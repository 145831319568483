<template>
  <div>
    <div class="wrap-it ce-paragraph ce-in" contenteditable="true" ref="editable" @keydown="onKeyDown" @blur="update"></div>
  </div>
</template>

<script>
// Inspiration from Editorjs https://github.com/editor-js/paragraph/blob/master/src/index.js
// and https://github.com/editor-js/list/blob/master/src/index.js
import scrollMeTo from '@shared/components/stepper/utils/scrollMeTo.js';

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    active: Boolean
  },
  mounted() {
    this.initText(this.value);
    this.initCaret(this.active);
  },
  watch: {
    value: function(value) {
      this.initText(value);
    },
    active: function(value) {
      this.initCaret(value);
    }
  },
  methods: {
    initText(value) {
      this.$refs.editable.innerText = value;
    },
    initCaret(value) {
      if (value === true) {
        scrollMeTo.setCaret(this.$refs.editable, 0);
      }
    },
    update(event) {
      let finalText = this.correctFormat(event.target.innerText);
      this.$emit('input', finalText);
    },
    onKeyDown(event) {
      const [ENTER, BACKSPACE] = [13, 8]; // key codes
      switch (event.keyCode) {
        case ENTER:
          this.getOutofOptions(event);
          break;
        case BACKSPACE:
          this.backspace(event);
          break;
      }
    },
    getOutofOptions(event) {
      let currentText = event.target.innerText;
      if (/\n\n/.test(currentText)) {
        let cleanText = event.target.innerText.replace('<br/>', '').replace('\n\n', '');
        let finalText = this.correctFormat(cleanText);
        event.target.innerText = finalText;
        this.$emit('input', finalText);
        this.$emit('newEntry');
        event.preventDefault();
        event.stopPropagation();
      }
    },
    correctFormat(text) {
      let arr = text.split("\n");
      let finalText = arr[0];
      for (let i = 1; i < arr.length; i++) {
        if (arr[i].trim() === "-" || arr[i].trim() === ""){
          continue;
        }
        if (arr[i][0] === "-"){
          finalText += ("\n" + arr[i]);
        } else {
          finalText += ("\n- " + arr[i]);
        }
      }
      return finalText;
    },
    backspace(event){
      let currentText = event.target.innerText;
      if(this.$utils.str.isEmpty(currentText)){
        this.$emit('input', '');
        event.preventDefault();
        event.stopPropagation();
      }
    }
  }
};
</script>

<style scoped>
.ce-paragraph {
  word-break: break-all;
  line-height: 1.6em;
  outline: none;
}

.ce-paragraph p:first-of-type {
  margin-top: 0;
}

.ce-paragraph p:last-of-type {
  margin-bottom: 0;
}

.wrap-it {
  white-space: pre-wrap;
}
</style>
