<template>
  <div>
    <label :for="elementId" class="question-label">{{ question }}</label>

    <div class="form-check col-s-12 col-md-6 col-lg-4">
      <input
        value="search"
        v-model="selected"
        class="form-check-input"
        type="radio"
        name="flexRadioDefault"
        id="radioOptionSearch"
      />
      <label class="form-check-label" for="radioOptionSearch"> Search Charities Commission </label>
    </div>
    <div class="form-check col-s-12 col-md-6 col-lg-4">
      <input
        value="manual"
        v-model="selected"
        class="form-check-input"
        type="radio"
        name="flexRadioDefault"
        id="radioOptionManual"
      />
      <label class="form-check-label" for="radioOptionManual"> Enter Charity Name </label>
    </div>

    <div v-if="selected === 'search'" class="col-s-12 col-md-6 col-lg-4" style="padding: 0; height: 40px;">
      <multiselect
        v-model.lazy="value"
        :options="options"
        placeholder="Search Charities Commission"
        label="name"
        :close-on-select="true"
        :hide-selected="true"
      ></multiselect>
    </div>
    
      <div v-if="selected === 'manual'" class="col-s-12 col-md-6 col-lg-4" style="padding: 0; height: 40px;">
        <input type="text" class="form-control" :id="elementId" :ref="elementId" v-model.lazy="value" />
      </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  components: {
    Multiselect,
  },
  props: {
    id: Number,
    question: String,
    type: String,
  },
  computed: {
    elementId() {
      return this.type + this.id;
    },
    value(){
      return this.$store.getters['editor/answer'](this.id);
    }
  },
  data() {
    return {
      options: [],
      selected: 'search',
    };
  },
};
</script>
