<template>
  <div class='list-group-item list-group-item-action flex-column'>
    <div class='media pt-2 pb-2'>
      <div class='media-body mb-0'>
        <div class='d-flex justify-content-between'>
          <h5 class='mb-1'>{{name}}</h5>
          <div class='d-flex justify-content-between'>
            <div><i title="Delete Proposal" class='fas fa-trash fa-lg action-icon-danger cool-danger' @click="onDelete"></i></div>
          </div>  
        </div>
        <p class='mb-1 text-muted'>
          <small>{{description}}</small>
        </p>
        <router-link class='cool-alt-link' :to="link">Edit</router-link>
    <span class="pr-2 pl-2"> | </span>
        <router-link class='cool-alt-link' :to="mapsLink">Maps</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: Number,
    name: String,
    description: String,
    link: String
  },
  computed: {
    mapsLink() {
      return `/demo/mapping/${this.id}`;
    }
  },
  methods: {
    onDelete(){
      this.$emit('delete', this.id)
    },
  }
};
</script>

<style scoped>
.badge-notify {
  position: relative;
  top: -10px;
  left: -10px;
}

.badge-warning-override{
  color:#ffc107 ;
}

.badge-success-override{
  color:#28a745;
}

.icon-holder{
  width: 50px;
  margin-right: -12px;
}

</style>