import axios from '@shared/services/api/axiosConfig.js';
import { getAuthHeaders } from '@shared/services/auth/authApiServices.js';
const debug = process.env.NODE_ENV !== 'production';


export default {
  async getTemplate(id) {
    const headers = await getAuthHeaders();
    const res = await axios.get(`/api/formTemplate/${id}`, headers)
    if (debug) console.log('getTemplate', res);
    return res;
  },
  async getDefault() {
    const headers = await getAuthHeaders();
    const res = await axios.get(`/api/formTemplate/default`, headers)
    if (debug) console.log('getTemplate', res);
    return res;
  },
  async getAll() {
    const headers = await getAuthHeaders();
    const res = await axios.get('/api/formTemplate/all/', headers)
    if (debug) console.log('template.getAll', res);
    return res;
  },
  async postEditTemplate(id, data) {
    const headers = await getAuthHeaders();
    const res = await axios.post(`/api/formTemplate/${id}`, data, headers)
    if (debug) console.log('postEditTemplate', res);
    return res;
  },
  async deleteTemplate(id) {
    const headers = await getAuthHeaders();
    const res = await axios.delete(`/api/formTemplate/${id}`, headers)
    if (debug) console.log('deleteTemplate', res);
    return res;
  },

}
