<template>
  <div :id="elementId">
    <label :for="elementId + '_in'" class="question-label">{{ question }}</label>
    <div>

      <VueSignaturePad height="300px" 
        style="border: 2px solid gray;"
        :ref="signatureRef"
      />    
    </div>
  </div>
</template>

<script>
export default {
  name: 'VQSign',
  props: {
    id: Number,
    question: String,
    type: String
  },
  mounted(){
    let data = this.$store.getters['editor/answer'](this.id);
    if (data) {
      this.$refs[this.signatureRef].fromDataURL(data)
    }
  },
  computed: {
    elementId() {
      return this.type + this.id;
    },
    signatureRef() {
      return 'Sig' + this.elementId;
    },
  }
};
</script>
