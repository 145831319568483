<template>
  <div class='choose-template-item list-group-item list-group-item-action flex-column' @click="$emit('click', $event.target.checked)" >
    <div class='media pt-2 pb-2'>
      <div class='media-body mb-0'>
        <div class='d-flex justify-content-between'>
          <h5 class='mb-1'>{{name}}</h5>
        </div>
        <p class='mb-1 text-muted'>
          <small>{{description}}</small>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: Number,
    name: String,
    description: String,
    link: String
  },
};
</script>

<style scoped>
.choose-template-item{
  cursor: pointer;
}

</style>