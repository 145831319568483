<template>
  <div>
    <h3>{{ title }}</h3>
    <VEditButton class="eb-sec" @click="update">Update</VEditButton>
    <h5>Next Element Id is: {{ nextElementId }}</h5>
    <div class="row">
      <div class="col">
        <textarea style="width:100%; height:70vh" v-model="internalForm"></textarea>
      </div>
    </div>
  </div>
</template>

<script>
const RAW_FORM_UPDATE = 'editor/rawFormUpdate';

export default {
  data() {
    return {
      internalForm: ""
    }
  },
  props: {
    title: {
      required: false,
      type: String
    },
    form: {
      required: true
    },
    nextElementId:{
      required: true,
      type: Number
    }
  },
  mounted () {
      const jsonStr = JSON.stringify(this.form, null, 2);
      this.internalForm = jsonStr;
  },
  watch: {
    form(newValue) {
      const jsonStr = JSON.stringify(newValue, null, 2);
      this.internalForm = jsonStr;
    }
  },
  methods: {
    update() {
      let payload = JSON.parse(this.internalForm);
      this.$store.commit(RAW_FORM_UPDATE, payload);
    }
  },
};
</script>

<style scoped></style>
